import store from '../../store/store';
import { useState } from 'react';
import '../../styles/pages/signup.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import MessageSVG from '../../ui/customSVGs/MessageSVG';
import LockSVG from '../../ui/customSVGs/LockSVG';
import LoginInput from '../../ui/inputs/LoginInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import RenderLoginPageLogo from '../login/RenderLoginPageLogo';
import Lottie from 'lottie-react';
import { requestUserSignUp } from '../../lib/api/sign-up';
import PasswordInput from '../../ui/inputs/PasswordInput';
import { motion } from 'framer-motion';
import lottieBanner from '../../assets/json/lottie_animation/cart.json';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import FooterLinks from '../../components/footer/FooterLinks';

const SignUpPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authToken = store.getState().auth.authorization.accessToken;
  // const [googleLoading, setGoogleLoading] = useState<boolean>(false);

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const merchantName = useSelector(
    (state: RootState) => state.user?.merchant?.config?.merchant_name
  );
  /*   const merchantName = useSelector(
    (state: RootState) => state.user?.merchant?.config?.merchant_name
  ); */
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  //getting cms or psp types from params and saving them in store
  /*   useEffect(() => {
    const params = window.location.search;
    if (params.includes('cms') && params.includes('psp')) {
      const str = params.substring(1).toLowerCase();
      const strArr = str.split('&');
      strArr.forEach((el: string) => {
        if (el.substring(0, 3) === 'cms') {
          const foundCMS = el.substring(4);
          console.log(`found cms: ${foundCMS}`);
          dispatch(setPreSelectedCMS_PSP({ type: 'cms', name: foundCMS }));
        } else if (el.substring(0, 3) === 'psp') {
          const foundPSP = el.substring(4);
          console.log(`found psp: ${foundPSP}`);
          dispatch(setPreSelectedCMS_PSP({ type: 'psp', name: foundPSP }));
        }
      });
    } else if (params.includes('cms')) {
      const str = params.substring(1).toLowerCase();
      const foundCMS = str.substring(4);
      console.log(`found cms: ${foundCMS}`);
      dispatch(setPreSelectedCMS_PSP({ type: 'cms', name: foundCMS }));
    } else if (params.includes('psp')) {
      const str = params.substring(1).toLowerCase();
      const foundPSP = str.substring(4);
      console.log(`found psp: ${foundPSP}`);
      dispatch(setPreSelectedCMS_PSP({ type: 'psp', name: foundPSP }));
    }
  }, []); */

  const isDisabled =
    password === '' ||
    !/[^ ]{8,20}/.test(password) ||
    email === '' ||
    !/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/.test(email);

  async function handleSignUpClick(e: any) {
    e.preventDefault();

    const sendingData = {
      email: email,
      password: password,
    };

    await requestUserSignUp(sendingData);
  }

  /* async function googleSignIn(e: any) {
    e.preventDefault();
    setGoogleLoading(true);
    try {
      const requestInfo: RequestInfo = {
        url: GOOGLE_LOGIN_ENDPOINT,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      const actionType = 'GOOGLE_LOGIN_VERIFICATION';
      const customMsg = 'Redirecting to Google Login.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        const { google_url } = response.result;
        window.open(`${google_url}`, '_self');
      } else {
        setGoogleLoading(false);

        console.log(response);
      }
    } catch (exception) {
      setGoogleLoading(false);
      console.log('Error during authentication request');
    }
  } */

  if (!authToken.sellerToken) {
    return (
      <motion.div {...pageSlideOutForward} className='signup'>
        <form onSubmit={handleSignUpClick} className='signup__left'>
          <div className='signup__logo'>
            <RenderLoginPageLogo
              staticURL={staticURL}
              merchantTheme={merchantTheme}
              isDarkMode={isDarkMode}
            />
          </div>
          <div className='signup__left--top'>
            <h1>
              {merchantTheme.signup_text1
                ? merchantTheme.signup_text1
                : t('SignUp')}
            </h1>
            <p>{t('SignUpSubText')}</p>
          </div>
          <div className='signup__left--center'>
            <LoginInput
              icon={<MessageSVG fill='#cecece' />}
              id='sign-up-form-email'
              value={email}
              type='email'
              changeListeners={[(e) => setEmail(e.target.value)]}
              focusListeners={[]}
              placeholder={t('EmailAddress')}
              required={true}
              pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
              title={t('Email-validation')}
            />
            <PasswordInput
              icon={<LockSVG fill='#cecece' />}
              id='sign-up-form-password'
              value={password}
              changeListeners={[(e) => setPassword(e.target.value)]}
              focusListeners={[]}
              placeholder={t('Password')}
              required={true}
              pattern='[^ ]{8,20}'
              title={t('Password-validation')}
            />
            <FooterLinks isRegisterPage={true} />
          </div>
          <div className='signup__left--bottom'>
            <MainSquareButton
              value={t('Continue')}
              type='submit'
              disabled={isDisabled}
              classes='mainSquareButton-extra-1'
            />
            <h2>
              {t('Or')}{' '}
              <span onClick={() => navigate('/login')}>{t('SignIn')} </span>
              {t('YourAccount')}
            </h2>
          </div>
          {/*  <div className='signup__left--other'>
            <img
              src={googleButton}
              alt='googleButton'
              onClick={!googleLoading ? googleSignIn : () => {}}
            />
          </div> */}
        </form>

        <div className='signup__right'>
          {/* <img src={bg} alt='signin-background' /> */}
          <div className='signup__right-lottie'>
            {merchantName !== 'AdUp' && merchantTheme?.signup_image ? (
              <div className='signup__right-lottie-img'>
                <img
                  src={`${staticURL}/medium/${merchantTheme?.signup_image}`}
                  alt='login-pic'
                />
              </div>
            ) : (
              <Lottie animationData={lottieBanner} loop={true} />
            )}
          </div>
        </div>
      </motion.div>
    );
  } else {
    window.location.assign('/');
    return null;
  }
};

export default SignUpPage;
