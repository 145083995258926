import { useTranslation } from 'react-i18next';
import { AI_CHECKOUT_TRACKING_LINK } from '../../../../lib/constants/central-api';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { CodeSection } from 'react-code-section-lib';
import SelectableButton from '../../../../ui/buttons/SelectableButton';
import { shopifySVG, webShopSVG } from '../../../../lib/allCustomSVGs';
import { useState } from 'react';

interface Props {
  apiKey: any;
}

function Integration({ apiKey }: Props) {
  const { t } = useTranslation();
  const isAppThemeDark = useSelector((state: RootState) => state.UI.theme.dark);
  const [isShopifyIntegration, setIsShopifyIntegration] =
    useState<boolean>(false);

  const regularScriptCode = `<!-- AI Checkout Tracking script -->
  <script defer>
    function loadAITrackingScript() {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      script.src = "${AI_CHECKOUT_TRACKING_LINK(
        apiKey
      )}&t=" + new Date().getTime();
      script.onload = function () {
       initializeAnalytics();
     };
     document.head.appendChild(script);
   }
   document.addEventListener("DOMContentLoaded",function(){const e=new Date,t=60*e.getHours()+e.getMinutes(),o=JSON.parse(localStorage.getItem("styleRecommendationsByAdUp"))||{};o&&"object"==typeof o&&Object.keys(o).forEach((e=>{const n=e.split("&t=")[0],c=e.split("&t=")[1];if("N"===c && t >= 0 && t < 360 || "M"===c && t >= 360 && t < 720 || "A"===c && t >= 720 && t < 1080 || "E"===c && t >= 1080 && t < 1440){const t=document.querySelector(n);if(t){const n=o[e];n&&"object"==typeof n&&Object.keys(n).forEach((e=>{t.style[e]=n[e]}))}}})),loadAITrackingScript()});
 </script>`;

  const shopifyPixelCode = `(function () {
  function loadAITrackingScript() {
    return new Promise((resolve, reject) => {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "${AI_CHECKOUT_TRACKING_LINK(
        apiKey
      )}&t=" + new Date().getTime();
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  function trackShopifyEvent(event) {
    setTimeout(() => {
      window.postMessage(
        {
          type: "shopify_web_pixel_event",
          payload: event,
        },
        "*"
      );
    }, 5000);
  }

  const eventsToTrack = [
    "cart_viewed",
    "checkout_address_info_submitted",
    "checkout_completed",
    "checkout_contact_info_submitted",
    "checkout_shipping_info_submitted",
    "checkout_started",
    "collection_viewed",
    "payment_info_submitted",
    "product_added_to_cart",
    "product_removed_from_cart",
    "product_viewed",
    "search_submitted",
  ];

  document.addEventListener("DOMContentLoaded",function(){const e=new Date,t=60*e.getHours()+e.getMinutes(),o=JSON.parse(localStorage.getItem("styleRecommendationsByAdUp"))||{};o&&"object"==typeof o&&Object.keys(o).forEach((e=>{const n=e.split("&t=")[0],c=e.split("&t=")[1];if("N"===c && t >= 0 && t < 360 || "M"===c && t >= 360 && t < 720 || "A"===c && t >= 720 && t < 1080 || "E"===c && t >= 1080 && t < 1440){const t=document.querySelector(n);if(t){const n=o[e];n&&"object"==typeof n&&Object.keys(n).forEach((e=>{t.style[e]=n[e]}))}}})),loadAITrackingScript()})
    .then(() => {
      if (window.initializeAnalytics) {
        window.initializeAnalytics();
      }
    })
    .catch((error) => {
      console.error("Error loading script:", error);
    })
    .finally(() => {
      eventsToTrack.forEach((eventName) => {
        analytics.subscribe(eventName, (event) => {
          trackShopifyEvent(event);
        });
      });
    });
})();`;

  return (
    <div className='normalForm'>
      <div className='normalForm__top'>
        {/* //* Integration method Selection -- Start */}
        <div className='shopPage__container1-alignLeft'>
          <SelectableButton
            icon={webShopSVG}
            title={''}
            subTitle={t('RegularIntegration')}
            subTitle2={t('RegularIntegration-Sub')}
            link=''
            onSelect={() => {
              setIsShopifyIntegration(false);
            }}
            isSelected={!isShopifyIntegration}
          />
          <SelectableButton
            icon={shopifySVG}
            title={''}
            subTitle={t('ShopifyIntegration')}
            subTitle2={t('ShopifyIntegration-Sub')}
            link=''
            onSelect={() => {
              setIsShopifyIntegration(true);
            }}
            isSelected={isShopifyIntegration}
          />
        </div>
        {/* //* Integration method Selection -- End */}

        {/* //* ----------- RegularIntegration -- Start ----------- */}
        {!isShopifyIntegration && (
          <>
            <h3>{t('CheckoutTrackingIntegrationInstructions1')}</h3>
            <ul style={{ marginLeft: '2rem', marginBottom: '-1.5rem' }}>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions2')}{' '}
                <strong>{`<head>`}</strong>
              </li>
            </ul>

            <CodeSection
              showLineNumbers
              theme={isAppThemeDark ? 'dark' : 'light'}
              lang={'html'}
            >
              {regularScriptCode}
            </CodeSection>
          </>
        )}
        {/* //* ----------- RegularIntegration -- End ----------- */}

        {/* //* ----------- ShopifyIntegration -- Start ----------- */}
        {isShopifyIntegration && (
          <>
            <h3>{t('CheckoutTrackingIntegrationInstructions1-Shopify')}</h3>
            <ul style={{ marginLeft: '2rem', marginBottom: '-1.5rem' }}>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions2-Shopify')}
              </li>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions3-Shopify')}
              </li>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions4-Shopify')}
              </li>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions5-Shopify')}
              </li>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions6-Shopify')}
              </li>
            </ul>

            <CodeSection
              showLineNumbers
              theme={isAppThemeDark ? 'dark' : 'light'}
              lang={'js'}
            >
              {shopifyPixelCode}
            </CodeSection>
          </>
        )}
        {/* //* ----------- ShopifyIntegration -- End ----------- */}
      </div>
    </div>
  );
}

export default Integration;
