import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addedCartSVG, completedCartSVG } from '../../../lib/allCustomSVGs';
import SimpleCard from '../../../ui/cards/SimpleCard';
import Lottie from 'lottie-react';
import PieChartComponent from '../../../components/charts/PieChartComponent';
import statsLottie from '../../../assets/json/lottie_animation/statsInfographicsLottie.json';
import setupTrackingLottie from '../../../assets/json/lottie_animation/setup_tracking.json';
import '../../../styles/pages/dashboard/stats/aiCheckoutTrackingStats.scss';
import { ShippingCostImpactLineChart } from './dummyStats/ShippingCostImpactLineChart';
import ColorUsageScatterChart from './dummyStats/ColorUsageScatterChart';
import BehavioralSegmentationRadarChart from './dummyStats/BehavioralSegmentationRadarChart';
import ConversionRateGroupedBarChart from './dummyStats/ConversionRateGroupedBarChart';
import ProductPurchaseBarChart from './dummyStats/ProductPurchaseBarChart';
import AverageCartValueLineChart from './dummyStats/AverageCartValueLineChart';
import UserActivityBubbleChart from './dummyStats/UserActivityBubbleChart';
import { APP_ENV } from '../../../lib/constants/central-api';

type Props = {
  trackerStats: any;
  isTrackable: boolean;
  isTimesUp: boolean;
};

const AiCheckoutTrackingStats = ({
  trackerStats,
  isTrackable,
  isTimesUp,
}: Props) => {
  const { t } = useTranslation();

  //initializing data
  const initialChartData = [
    { name: t('TotalCarts'), value: 0, color: '#8884d8', svg: addedCartSVG },
    {
      name: t('TotalPurchases'),
      value: 0,
      color: '#82ca9d',
      svg: completedCartSVG,
    },
  ];

  const initialAdditionalChartData = [
    {
      name: t('TotalCarts'),
      value: 0,
      color: '#8884d8',
      svg: addedCartSVG,
    },
    {
      name: t('TotalPurchases'),
      value: 0,
      color: '#82ca9d',
      svg: completedCartSVG,
    },
  ];

  const [additionalChartData, setAdditionalChartData] = useState(
    initialAdditionalChartData
  );
  const [chartData, setChartData] = useState(initialChartData);
  const [conversionRate, setConversionRate] = useState(0);

  useEffect(() => {
    if (trackerStats) {
      const totalCarts = trackerStats?.total_carts
        ? trackerStats.total_carts
        : 0;
      const totalPurchases = trackerStats?.total_purchases
        ? trackerStats.total_purchases
        : 0;
      const conversionRate = trackerStats?.conversion_rate
        ? parseFloat(trackerStats.conversion_rate?.toFixed(2))
        : 0;
      const remainingRate = parseFloat((100 - conversionRate)?.toFixed(2));

      setChartData([
        {
          name: t('Purchases'),
          value: conversionRate,
          color: '#82ca9d',
          svg: completedCartSVG,
        },
        {
          name: t('RemainingCarts'),
          value: remainingRate,
          color: '#8884d8',
          svg: addedCartSVG,
        },
      ]);

      setAdditionalChartData([
        {
          name: t('TotalCarts'),
          value: totalCarts,
          color: '#8884d8',
          svg: addedCartSVG,
        },
        {
          name: t('TotalPurchases'),
          value: totalPurchases,
          color: '#82ca9d',
          svg: completedCartSVG,
        },
      ]);

      setConversionRate(conversionRate);
    }
  }, [trackerStats]);

  const renderCharts = () => {
    if (isTimesUp && APP_ENV === 'LIVE') {
      /* 
      return (
        <PieChartComponent
          data={chartData}
          additionalData={additionalChartData}
          width='100%'
          height={300}
          innerRadius={120}
          title={t('ConversionRate')}
          isChartVisible={chartData[0].value !== 0 || chartData[1].value !== 0} // If there is no data, don't show the chart
          comment={`${t('ConversionRateComment')} ${conversionRate?.toFixed(
            2
          )}%`}
          isChartDataVisible={false}
        /> 
      );
      */

      return (
        <SimpleCard grow={false}>
          <div className='aiCheckoutTrackingStats__card'>
            <h3>{t('TrendsText1')}</h3>
            <div className='aiCheckoutTrackingStats__card--lottie'>
              <Lottie animationData={statsLottie} loop={true} />
            </div>
          </div>
        </SimpleCard>
      );
    } else if (isTimesUp && APP_ENV !== 'LIVE') {
      return (
        <div className='dummyChart'>
          <div className='dummyChart--content'>
            <PieChartComponent
              data={chartData}
              additionalData={additionalChartData}
              width='100%'
              height={300}
              innerRadius={120}
              title={t('ConversionRate')}
              isChartVisible={
                chartData[0].value !== 0 || chartData[1].value !== 0
              } // If there is no data, don't show the chart
              comment={`${t('ConversionRateComment')} ${conversionRate?.toFixed(
                2
              )}%`}
              isChartDataVisible={false}
            />

            <BehavioralSegmentationRadarChart
              width='100%'
              height={300}
              title={t('User Engagement Through Time Spent and Clicks')}
            />
          </div>
          <div className='dummyChart--content'>
            <ProductPurchaseBarChart
              width='100%'
              height={300}
              title={t(
                'Product Purchase Distribution Across European Countries'
              )}
            />
          </div>
          <div className='dummyChart--content'>
            <ColorUsageScatterChart
              width='100%'
              height={300}
              title={t('Button Color Usage')}
            />
            <ShippingCostImpactLineChart
              width='100%'
              height={300}
              title={t('Impact of Shipping Costs on Conversion Rates')}
            />
          </div>
          <div className='dummyChart--content'>
            <UserActivityBubbleChart
              width='100%'
              height={300}
              title={t('Weekly User Activity Overview')}
            />
          </div>
          <div className='dummyChart--content'>
            <AverageCartValueLineChart
              width='100%'
              height={300}
              title={t('Average Cart Value Over Time')}
            />
            <ConversionRateGroupedBarChart
              width='100%'
              height={300}
              title={t(
                'Device-Based Conversion Rates for Electronics and Clothing'
              )}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className='aiCheckoutTrackingStats--container'>
      <div className='aiCheckoutTrackingStats'>
        {isTrackable && !isTimesUp && (
          <SimpleCard grow={false}>
            <div className='aiCheckoutTrackingStats__card'>
              <h3>{t('TrendsText1')}</h3>
              <div className='aiCheckoutTrackingStats__card--lottie'>
                <Lottie animationData={statsLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {!isTrackable && !isTimesUp && (
          <SimpleCard grow={false}>
            <div className='aiCheckoutTrackingStats__card'>
              <h3>{t('TrendsText2')}</h3>
              <div className='aiCheckoutTrackingStats__card--lottie'>
                <Lottie animationData={setupTrackingLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {renderCharts()}
      </div>
    </div>
  );
};

export default AiCheckoutTrackingStats;
