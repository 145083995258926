import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Define the type for the raw data
interface RawData {
  category: string;
  products: 'Electronics' | 'Clothing';
  conversionRate: number;
}

// Define the type for the transformed data
interface TransformedData {
  category: string;
  Electronics?: number;
  Clothing?: number;
}

// Realistic dummy data
const rawData: RawData[] = [
  { category: 'Mobile', products: 'Electronics', conversionRate: 1.8 },
  { category: 'Mobile', products: 'Clothing', conversionRate: 1.5 },
  { category: 'Desktop', products: 'Electronics', conversionRate: 3.0 },
  { category: 'Desktop', products: 'Clothing', conversionRate: 2.7 },
  { category: 'Tablet', products: 'Electronics', conversionRate: 2.1 },
  { category: 'Tablet', products: 'Clothing', conversionRate: 1.9 },
];

// Transform the data to be usable in the BarChart
const data: TransformedData[] = rawData.reduce<TransformedData[]>(
  (acc, { category, products, conversionRate }) => {
    const existingCategory = acc.find((item) => item.category === category);
    if (existingCategory) {
      existingCategory[products] = conversionRate;
    } else {
      acc.push({ category, [products]: conversionRate });
    }
    return acc;
  },
  []
);

interface Props {
  width: string | number;
  height: number;
  title?: string;
}

const ConversionRateGroupedBarChart = ({ width, height, title }: Props) => {
  return (
    <div className='pieChartComponent'>
      {title && <h2 className='pieChartComponent__title'>{title}</h2>}
      <div className='pieChartComponent__chart'>
        <ResponsiveContainer width={width} height={height}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, bottom: 20, left: 20 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='category' />
            <YAxis tickFormatter={(value) => `${value}%`} />
            <Tooltip formatter={(value) => `${value}%`} />
            <Legend />
            <Bar dataKey='Electronics' stackId='a' fill='#8884d8' />
            <Bar dataKey='Clothing' stackId='a' fill='#82ca9d' />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ConversionRateGroupedBarChart;
