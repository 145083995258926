import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/checkoutTracking/recommendationsPayment.scss';
import Slider from '@mui/material/Slider';

type Props = {
  setPaymentValue: (value: number) => void;
  paymentValue: number;
};

const RecommendationsPayment = ({ setPaymentValue, paymentValue }: Props) => {
  const { t } = useTranslation();

  const handleChange = (event: Event, newValue: number | number[]) => {
    setPaymentValue(newValue as number);
  };

  return (
    <div className='recommendationsPayment'>
      <div className='recommendationsPayment__title'>
        <h2>{t('PayWhatYouWant')}</h2>
        <span>{t('PayWhatYouWant2')}</span>
      </div>

      {/* make this amount label to an input to edit the number do it in the future */}
      <h2 className='recommendationsPayment__amount'>{'€' + paymentValue}</h2>
      <div className='recommendationsPayment__input'>
        {/*   <Slider
          aria-label='Restricted values'
          defaultValue={paymentValue}
          onChange={handleChange}
          step={5}
          valueLabelDisplay='auto'
          marks={marks}
          min={5}
          max={25}
          className={'recommendationsPayment__input--slider'}
        /> */}

        <Slider
          defaultValue={paymentValue}
          onChange={handleChange}
          valueLabelDisplay='auto'
          min={15}
          max={500}
        />
      </div>
      <div className='recommendationsPayment__button'>Pay</div>
    </div>
  );
};

export default RecommendationsPayment;
