import React from 'react';
import { ResponsiveContainer } from 'recharts';
import {
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
  Legend,
} from 'recharts';

// Realistic dummy data
const data = [
  { behavior: 'High Engagement', timeSpent: 300, clicks: 50, color: '#4caf50' },
  {
    behavior: 'Moderate Engagement',
    timeSpent: 180,
    clicks: 30,
    color: '#ff9800',
  },
  { behavior: 'Low Engagement', timeSpent: 60, clicks: 10, color: '#f44336' },
  {
    behavior: 'Very High Engagement',
    timeSpent: 450,
    clicks: 70,
    color: '#2196f3',
  },
  {
    behavior: 'Very Low Engagement',
    timeSpent: 20,
    clicks: 5,
    color: '#9e9e9e',
  },
];

interface Props {
  width: string | number;
  height: number;
  title?: string;
}

const BehavioralSegmentationRadarChart = ({ width, height, title }: Props) => {
  // Data formatting for RadarChart
  const radarData = data.map((item) => ({
    behavior: item.behavior,
    timeSpent: item.timeSpent / 10,
    clicks: item.clicks / 10,
  }));

  return (
    <div className='pieChartComponent'>
      {title && <h2 className='pieChartComponent__title'>{title}</h2>}
      <div className='pieChartComponent__chart'>
        <ResponsiveContainer width={width} height={height}>
          <RadarChart outerRadius={120} data={radarData}>
            <PolarGrid />
            <PolarAngleAxis dataKey='behavior' />
            <PolarRadiusAxis angle={30} domain={[0, 50]} />
            <Radar
              name='Time Spent'
              dataKey='timeSpent'
              stroke='#4caf50'
              fill='#4caf50'
              fillOpacity={0.6}
            />
            <Radar
              name='Clicks'
              dataKey='clicks'
              stroke='#ff9800'
              fill='#ff9800'
              fillOpacity={0.6}
            />
            <Tooltip />
            <Legend />
          </RadarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BehavioralSegmentationRadarChart;
