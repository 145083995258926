import { useEffect, useState } from 'react';
import '../../../../styles/pages/checkoutTracking/recommendationItem.scss';
import { initialInputData } from '../../../../lib/constants/trackingClassesData';
import { useTranslation } from 'react-i18next';
import RecommendationOption from './RecommendationOption';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { reloadArrowsSVG } from '../../../../lib/allCustomSVGs';
import regenerate_recommendations_api_call from '../../../../lib/api/ai-tracker/regenerate_recommendations_api_call';

// Define interfaces for better type safety
interface Option {
  night?: any[];
  morning?: any[];
  afternoon?: any[];
  evening?: any[];
}

interface ElementName {
  name: string;
  label: string;
}

type Props = {
  data: Record<string, Option> | null;
  setAllRecommendations: (data: any) => void;
  allRecommendations: Record<string, any>[];
};

const RecommendationItem = ({
  data,
  setAllRecommendations,
  allRecommendations,
}: Props) => {
  const { t } = useTranslation();
  const [element, setElement] = useState<ElementName | null>(null);
  const [nightOptions, setNightOptions] = useState<any[]>([]);
  const [morningOptions, setMorningOptions] = useState<any[]>([]);
  const [afternoonOptions, setAfternoonOptions] = useState<any[]>([]);
  const [eveningOptions, setEveningOptions] = useState<any[]>([]);

  useEffect(() => {
    if (data && typeof data === 'object') {
      // Get the element name from the key
      const key = Object.keys(data)[0]; // Safe access to the first key
      if (key) {
        const elementName = initialInputData?.find((item) => item.name === key);
        setElement(elementName || null);

        // Get the options from values
        const allOptions = data[key];
        if (allOptions) {
          setNightOptions(allOptions?.night || []);
          setMorningOptions(allOptions?.morning || []);
          setAfternoonOptions(allOptions?.afternoon || []);
          setEveningOptions(allOptions?.evening || []);
        }
      }
    }
  }, [data]);

  const regenerateRecommendation = async () => {
    if (!element) return; // Guard clause for null element

    try {
      const res = await regenerate_recommendations_api_call(element.name);

      // Check if res is an object and has the expected key
      if (res && typeof res === 'object' && element.name in res) {
        const newRecommendations = res[element.name];
        const updatedRecommendations = allRecommendations.map((item) => {
          if (item[element.name]) {
            return { [element.name]: newRecommendations };
          } else {
            return item;
          }
        });

        setAllRecommendations(updatedRecommendations);
      } else {
        console.error('Invalid API response structure:', res);
      }
    } catch (error) {
      console.error('Error Regenerating Recommendation:', error);
    }
  };

  const isRegenerateDisabled = true;

  return (
    <div className='recommendationItem'>
      <div className='recommendationItem__element'>
        <div className='recommendationItem__element--label'>
          {element?.label || ''}
        </div>
        {!isRegenerateDisabled && (
          <div
            onClick={regenerateRecommendation}
            className='recommendationItem__element--regenerate'
          >
            <span>{t('Regenerate')}</span>
            <CustomSVGs svg={reloadArrowsSVG} />
          </div>
        )}
      </div>
      <div className='recommendationItem__section--container'>
        {nightOptions.length > 0 && (
          <RecommendationOption
            sectionName={t('Night')}
            options={nightOptions}
            setOptions={setNightOptions}
          />
        )}

        {morningOptions.length > 0 && (
          <RecommendationOption
            sectionName={t('Morning')}
            options={morningOptions}
            setOptions={setMorningOptions}
          />
        )}

        {afternoonOptions.length > 0 && (
          <RecommendationOption
            sectionName={t('Afternoon')}
            options={afternoonOptions}
            setOptions={setAfternoonOptions}
          />
        )}

        {eveningOptions.length > 0 && (
          <RecommendationOption
            sectionName={t('Evening')}
            options={eveningOptions}
            setOptions={setEveningOptions}
          />
        )}
      </div>
    </div>
  );
};

export default RecommendationItem;
