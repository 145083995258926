import React from 'react';
import { tickSVG } from '../../../../lib/allCustomSVGs';
import '../../../../styles/pages/checkoutTracking/recommendationItem.scss';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import select_recommendation_api_call from '../../../../lib/api/ai-tracker/select_recommendation_api_call';

type Props = {
  sectionName: string;
  options: any[];
  setOptions: (options: any[]) => void;
};
const RecommendationOption = ({ sectionName, options, setOptions }: Props) => {
  const selectRecommendation = async (isSelected: boolean, id: string) => {
    try {
      const res = await select_recommendation_api_call(isSelected, id);
      if (res) {
        console.log('Recommendation updated successfully');
      }
    } catch (error) {
      console.error('Error updating recommendation:', error);
    }
  };

  return (
    <div className='recommendationItem__section'>
      <div className='recommendationItem__section--title'>{sectionName}</div>
      <div className='recommendationItem__options'>
        {options?.map((option) => (
          <div
            onClick={async () => {
              const updatedOptions = await Promise.all(
                options.map(async (item) => {
                  if (item.id === option.id) {
                    await selectRecommendation(!item.is_selected, item.id);
                    return {
                      ...item,
                      is_selected: item.is_selected === 1 ? 0 : 1,
                    };
                  } else {
                    return { ...item, is_selected: 0 };
                  }
                })
              );
              setOptions(updatedOptions);
            }}
            style={option?.response?.styles ? option.response.styles : {}}
            className='recommendationItem__option'
            key={option?.id}
          >
            <div className='recommendationItem__option--tick'>
              <div className='recommendationItem__option--tick-container'>
                {option?.is_selected === 1 && <CustomSVGs svg={tickSVG} />}
              </div>
            </div>
            <div className='recommendationItem__option--details'>
              {option?.response?.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecommendationOption;
