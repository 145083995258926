import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { motion } from 'framer-motion';
import PSPSelector from '../../components/selection/PSPSelector';
import {
  OnboardingStagesMap,
  setStageComplete,
  updateIntercom,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import { useEffect, useState } from 'react';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import '../../styles/components/onboarding/setShopPSPs1.scss';
import { useNavigate } from 'react-router-dom';
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
import { useTranslation } from 'react-i18next';
import { setIsOnboardingCompleteBannerOpen } from '../../store/reducers/uiSlice';
import { setRunInitOnFirstLoad } from '../../store/reducers/appSlice';
import get_all_psp_api_call from '../../lib/api/psp/get-all-available-psp';
import FooterLinks from '../../components/footer/FooterLinks';

const SetShopPSPs1 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [allAvailablePSPServices, setAllAvailablePSPServices] = useState([]);
  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);
  const whiteLabelRedirectPath = useSelector(
    (state: RootState) => state.auth.authorization.whiteLabelRedirectPath
  );
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  const selectedPSP = useSelector(
    (state: RootState) => state.user.company.pspSelected
  );

  const navigate = useNavigate();

  useEffect(() => {
    async function allAvailablePSP() {
      const res: any = await get_all_psp_api_call();
      setAllAvailablePSPServices(res);
    }
    allAvailablePSP();
  }, []);

  /* async function handleContinue() {
    if (selectedPSP?.toString() === 'nopsp') {
      dispatch(
        updateIntercom({
          psp: 'nopsp',
        })
      );
      const statusRes = await submitOnboardingStatus('psp', 'nopsp');
      if (statusRes) {
        await skip();
      }
    } else {
      if (selectedPSP?.toString() === 'other') {
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.SettingPSPConnection.name)
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.IntegratingPSP1.name,
            isCompleted: true,
          })
        );
      } else {
        dispatch(
          updateIntercom({
            psp: selectedPSP?.toString(),
          })
        );
        const statusRes = await submitOnboardingStatus(
          'psp',
          selectedPSP?.toString()
        );
        if (statusRes) {
          dispatch(
            updateOnboardingStage(OnboardingStagesMap.SettingPSPConnection.name)
          );
          dispatch(
            setStageComplete({
              stage: OnboardingStagesMap.IntegratingPSP1.name,
              isCompleted: true,
            })
          );
        }
      }
    }
  } */

  //TODO: When the submitOnboardingStatus ENDPOINT is completed, delete this function and uncomment the above function and use it.
  async function handleContinue() {
    if (selectedPSP?.toString() === 'nopsp') {
      dispatch(
        updateIntercom({
          psp: 'nopsp',
        })
      );
      skip();
    } else {
      if (selectedPSP?.toString() === 'other') {
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.SettingPSPConnection.name)
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.IntegratingPSP1.name,
            isCompleted: true,
          })
        );
      } else {
        dispatch(
          updateIntercom({
            psp: selectedPSP?.toString(),
          })
        );

        dispatch(
          updateOnboardingStage(OnboardingStagesMap.SettingPSPConnection.name)
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.IntegratingPSP1.name,
            isCompleted: true,
          })
        );
      }
    }
  }

  const navigateToNextStage = () => {
    if (isWhiteLabel) {
      if (whiteLabelRedirectPath && whiteLabelRedirectPath !== '') {
        navigate(whiteLabelRedirectPath);
      } else {
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.WebshopTransactions.name)
        );
      }
    } else {
      dispatch(
        updateOnboardingStage(OnboardingStagesMap.WebshopTransactions.name)
      );
    }
  };

  async function skip() {
    navigateToNextStage();
    dispatch(
      setStageComplete({
        stage: OnboardingStagesMap.IntegratingPSP1.name,
        isCompleted: true,
      })
    );
  }

  const isDisabled = selectedPSP === '' ? true : false;
  return (
    <motion.div
      {...pageSlideOutForward}
      className='setShopPSPs1 animate-fade_in_from_right'
    >
      <div
        // style={{ opacity: allAvailablePSPServices?.length === 1 ? '0' : '1' }}
        className='setShopPSPs1-container'
      >
        <div className='setShopPSPs1--top'>
          <h1>{t('OnboardingPSP-SelectH1')}</h1>
          <h2>{t('OnboardingPSP-SelectH2')}</h2>
        </div>
        {allAvailablePSPServices?.length > 0 ? (
          <PSPSelector options={allAvailablePSPServices} />
        ) : (
          <div className='pspSelector'>
            <h1>Loading...</h1>
          </div>
        )}

        <motion.div className='setShopPSPs1--bottom'>
          <MainPurpleButton
            onClick={() => handleContinue()}
            value={t('Next')}
            disabled={isDisabled}
            classes='text-white rounded-full'
          />
          <div className='pt-2'>
            <a className='skip-link' onClick={skip}>
              {t('SkipStep')}
            </a>
          </div>
        </motion.div>
        {/* )} */}
      </div>

      <div className='setShopPSPs1--footer'>
        <FooterLinks />
      </div>
    </motion.div>
  );
};

export default SetShopPSPs1;
