import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { RECOMMENDATIONS_API } from '../../constants/central-api';
import { applicationWWWXRLEncodedBodyBuilder } from '../../utils/body-builder';

export default async function select_recommendation_api_call(
  isSelected: boolean,
  recommendationID: string
) {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const is_selected = isSelected ? '/select' : '/unselect';

  const requestInfo: RequestInfo = {
    url: RECOMMENDATIONS_API + is_selected,
    method: 'PATCH',
    body: applicationWWWXRLEncodedBodyBuilder({
      id: recommendationID,
    }),
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: `${accessToken.type} ${sellerToken}`,
    },
  };

  const actionType = 'PATCH_RECOMMENDATIONS';
  const customMsg = 'Recommendation updated successfully';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;
  return response.success && res.actionType === actionType;
}
