import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// Dummy data
const data = [
  { month: 'Jan', averageCartValue: 50 },
  { month: 'Feb', averageCartValue: 55 },
  { month: 'Mar', averageCartValue: 53 },
  { month: 'Apr', averageCartValue: 65 },
  { month: 'May', averageCartValue: 70 },
  { month: 'Jun', averageCartValue: 75 },
  { month: 'Jul', averageCartValue: 80 },
  { month: 'Aug', averageCartValue: 85 },
  { month: 'Sep', averageCartValue: 60 },
  { month: 'Oct', averageCartValue: 95 },
  { month: 'Nov', averageCartValue: 100 },
  { month: 'Dec', averageCartValue: 105 },
];

interface Props {
  width: string | number;
  height: number;
  title?: string;
}

const AverageCartValueLineChart = ({ width, height, title }: Props) => {
  return (
    <div className='pieChartComponent'>
      {title && <h2 className='pieChartComponent__title'>{title}</h2>}
      <div className='pieChartComponent__chart'>
        <ResponsiveContainer width={width} height={height}>
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, bottom: 20, left: 20 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='month' />
            <YAxis />
            <Tooltip />
            <Line
              type='monotone'
              dataKey='averageCartValue'
              stroke='#8884d8'
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AverageCartValueLineChart;
