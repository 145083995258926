import React, { useState } from 'react';
import '../../styles/inputs/passwordInput.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';
import { eyeCrossedSVG, eyeSVG } from '../../lib/allCustomSVGs';
import { useTranslation } from 'react-i18next';

type props = {
  icon: any;
  required?: boolean;
  value: string;
  placeholder?: string;
  changeListeners?: ((e: React.ChangeEvent<HTMLInputElement>) => void)[];
  focusListeners?: ((e: React.FocusEvent) => void)[];
  id: string;
  classes?: string;
  pattern?: string;
  title?: string;
};

const PasswordInput = React.forwardRef<HTMLInputElement, props>(
  (
    {
      icon,
      value,
      changeListeners,
      focusListeners,
      placeholder = '',
      required,
      id,
      classes = '',
      pattern,
      title,
    }: props,
    ref
  ) => {
    const { t } = useTranslation();
    const [innerValue, setValue] = useState<any>(value);
    const [focused, setFocused] = useState<boolean>(false);
    const [onlyOnFocus, setOnlyOnFocus] = useState<boolean>(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };

    function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
      setValue(event.target.value);
      changeListeners?.forEach((listener) => listener(event));
    }

    function handleFocusElement(event: React.FocusEvent<HTMLInputElement>) {
      focusListeners?.forEach((listener) => listener(event));
      setOnlyOnFocus(true);
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      setOnlyOnFocus(false);
    };

    return (
      <>
        <div className='passwordInput'>
          <div className='passwordInput__left passwordInput__left--icon'>
            {icon}
          </div>
          <div className='passwordInput__right'>
            <input
              className={`passwordInput-input ${classes}`}
              style={{ fontFamily: 'var(--main-font-secondary)' }}
              ref={ref}
              id={id}
              type={passwordShown ? 'text' : 'password'}
              value={innerValue}
              onChange={handleChangeValue}
              onFocus={handleFocusElement}
              onBlur={handleBlur}
              data-focused={focused.toString()}
              required={required}
              pattern={pattern}
              title={title}
            />
            <label htmlFor={id}>{placeholder}</label>
          </div>
          <div
            title={passwordShown ? t('HideShowPassword') : t('ShowPassword')}
            onClick={togglePasswordVisibility}
            className={`passwordInput__visibility ${
              passwordShown
                ? 'passwordInput__visibility--true'
                : 'passwordInput__visibility--false'
            }`}
          >
            {passwordShown ? (
              <CustomSVGs svg={eyeCrossedSVG} />
            ) : (
              <CustomSVGs svg={eyeSVG} />
            )}
          </div>
        </div>

        <div className='passwordInput-validation-text'>
          {onlyOnFocus && title}
        </div>
      </>
    );
  }
);

export default PasswordInput;
