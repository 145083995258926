const version = 'v1';

let basePath = ''; // base URL of the APIs
let trackerBasePath = ''; // base URL of tracker APIs
let aiCheckoutTrackingScriptPath = '';
let env: 'DEV' | 'STAGING' | 'LIVE';
export const BUGSNAG_API_KEY = '0c051d898b4c705d9f58d66045fc561e';
if (
  window.location.href.split('/')[2].includes('localhost') ||
  process.env.NODE_ENV === 'development' ||
  window.location.href.split('/')[2].includes('dev')
) {
  basePath = 'https://centralapi-dev.kodeia.com/api';
  trackerBasePath = 'https://ai-tracker-dev.kodeia.com/api';
  aiCheckoutTrackingScriptPath =
    'https://static.adup.io/scripts/ai-tracking-development/dist/AITracking.js';
  env = 'DEV';
} else if (window.location.href.split('/')[2].includes('staging')) {
  basePath = 'https://centralapi-staging.kodeia.com/api';
  trackerBasePath = 'https://ai-tracker-staging.kodeia.com/api';
  aiCheckoutTrackingScriptPath =
    'https://static.adup.io/scripts/ai-tracking-staging/dist/AITracking.js';
  env = 'STAGING';
} else {
  basePath = 'https://centralapi.adup.io/api';
  trackerBasePath = 'https://ai-tracker.adup.io/api';
  aiCheckoutTrackingScriptPath =
    'https://static.adup.io/scripts/ai-tracking/dist/AITracking.js';
  env = 'LIVE';
}

export const CACHE_BUSTER_EXECUTION_INTERVAL = 30 * 60 * 1000; //* 30 minutes
export const APP_ENV = env;
export const CENTRAL_API_BASE_URL = `${basePath}/${version}/`;
export const TRACKER_API_BASE_URL = `${trackerBasePath}/${version}/`;

/* ----------AUTHENTICATION ENDPOINT----------- */
export const LOGIN_API = CENTRAL_API_BASE_URL + 'login';
export const LOGOUT_API = CENTRAL_API_BASE_URL + 'logout';

export const REGISTRATION_API = (merchantSlug: string) => {
  return `${CENTRAL_API_BASE_URL}merchants/${merchantSlug}/sellers`;
};

/* ----------INIT ENDPOINT----------- */
export const INIT_API = CENTRAL_API_BASE_URL + 'seller-init';

/* ----------SHOP ENDPOINT----------- */
export const SHOP_API = CENTRAL_API_BASE_URL + `shops`;
export const CREATE_SHOP_API = (sellerID: string) => {
  return `${CENTRAL_API_BASE_URL}sellers/${sellerID}/shops`;
};

/* ----------SETTINGS ENDPOINT----------- */
export const GENERATE_AUTH_KEY_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/generate-auth-key`;
};

/* ----------SHIPPING ENDPOINT----------- */
export const SHIPPING_API = CENTRAL_API_BASE_URL + `shipping`;

/* ----------DISCOUNTS ENDPOINT----------- */
export const DISCOUNTS_TYPES_API = CENTRAL_API_BASE_URL + `discounts/types`;
export const DISCOUNTS_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/discounts`;
};

/* ----------CONVENIENCE FEE ENDPOINT----------- */
export const CONVENIENCE_FEE_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}convenience-fee/${shopSlug}`;
};

/* ----------INTEGRATION ENDPOINT----------- */
export const INTEGRATION_API = CENTRAL_API_BASE_URL + `integrations`;
export const GET_ALL_INTEGRATIONS_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/integrations`;
};

/* ----------PRODUCT ENDPOINT----------- */
export const PRODUCT_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/products`;
};
export const PRODUCT_SYNC_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/sync`;
};

/* ----------ORDERS ENDPOINT----------- */
export const ORDERS_API = CENTRAL_API_BASE_URL + `orders`;

/* ----------CMS ENDPOINT----------- */
export const GET_ALL_CMS_SERVICES_API = CENTRAL_API_BASE_URL + `cms`;
export const CMS_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/cms`;
};

/* ----------PSP ENDPOINT----------- */
export const GET_ALL_PSP_SERVICES_API =
  CENTRAL_API_BASE_URL + `payment-services`;
export const PSP_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${shopSlug}/psp`;
};

/* ----------STATISTICS ENDPOINT----------- */
export const STATISTICS_BY_SHOP_API = (shopSlug: string) => {
  return `${CENTRAL_API_BASE_URL}statistics/shop/${shopSlug}`;
};

export const CONVERSION_RATE_STATS_API = (integrationKey: string) => {
  return `${TRACKER_API_BASE_URL}company/${integrationKey}/stats/conversion-rate`;
};

/* ----------HELP ENDPOINT----------- */
export const HELP_LINE_API = `${CENTRAL_API_BASE_URL}help-line`;

/* ----------AI_CHECKOUT_TRACKING_LINK----------- */
export const AI_CHECKOUT_TRACKING_LINK = (key: string) => {
  return `${aiCheckoutTrackingScriptPath}?trackingId=${key}`;
};

export const GET_ACCESS_TOKEN_BY_INTEGRATION_KEY_API = (apiKey: string) => {
  return `${CENTRAL_API_BASE_URL}ai-tracker/access-token/${apiKey}`;
};

export const RECOMMENDATIONS_API = `${CENTRAL_API_BASE_URL}ai-tracker/recommendations`;
