import '../../styles/components/charts/pieChartComponent.scss';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Tooltip,
  LabelProps,
  TooltipProps,
} from 'recharts';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';

interface PieChartProps {
  data: { name: string; value: number; color: string; svg?: JSX.Element }[];
  additionalData?: {
    name: string;
    value: number;
    color: string;
    svg?: JSX.Element;
  }[];
  width?: string | number;
  height?: number;
  innerRadius?: number;
  comment?: string;
  title?: string;
  isChartVisible?: boolean;
  isChartDataVisible?: boolean;
}

const PieChartComponent: React.FC<PieChartProps> = ({
  data,
  additionalData,
  width = '100%',
  height = 300,
  innerRadius = 80,
  comment,
  title,
  isChartVisible = true,
  isChartDataVisible = true,
}) => {
  const theme = 'light';
  const backgroundColor = theme === 'light' ? '#fff' : '#333';

  const renderCustomizedLabel = ({ value }: LabelProps) => `${value}%`;

  // Custom tooltip renderer function
  const renderTooltipContent = (props: TooltipProps<any, any>) => {
    if (!props.active || !props.payload || props.payload.length === 0)
      return null;

    const value = props.payload[0].value;
    const name = props.payload[0].name;

    return (
      <div className='pieChartComponent-custom-tooltip'>
        <p className='pieChartComponent-custom-tooltip-label'>{name}:</p>
        <p className='pieChartComponent-custom-tooltip-value'>{` ${value}%`}</p>
      </div>
    );
  };

  return (
    <div className='pieChartComponent'>
      {title && <h2 className='pieChartComponent__title'>{title}</h2>}
      <div className='pieChartComponent__chart'>
        {isChartVisible && (
          <ResponsiveContainer width={width} height={height}>
            <PieChart>
              <Tooltip content={renderTooltipContent} />
              <Pie
                data={data}
                dataKey='value'
                nameKey='name'
                cx='50%'
                cy='50%'
                outerRadius={innerRadius}
                fill={backgroundColor}
                label={renderCustomizedLabel}
              >
                {data?.map((item, index) => (
                  <Cell key={`cell-${index}`} fill={item?.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
      <div className='pieChartComponent__info-container'>
        {isChartDataVisible && (
          <div className='pieChartComponent__info'>
            {data?.map((item, index) => (
              <div key={index} className='pieChartComponent__info--item'>
                {/* ----------------------------------------------- */}
                {item?.svg && (
                  <div className='pieChartComponent__info--item-icon'>
                    <CustomSVGs svg={item?.svg} fillColor={item?.color} />
                  </div>
                )}
                {/* ----------------------------------------------- */}
                {!item?.svg && (
                  <div
                    style={{ backgroundColor: item?.color }}
                    className='pieChartComponent__info--item-dot'
                  ></div>
                )}
                {/* ----------------------------------------------- */}
                <div className='pieChartComponent__info--item-text'>
                  <span>{item.name}:</span>
                  <span>{item.value}</span>
                </div>
                {/* ----------------------------------------------- */}
              </div>
            ))}
          </div>
        )}

        {additionalData && (
          <div
            className={`pieChartComponent__info ${
              isChartDataVisible ? 'pieChartComponent__info--additional' : ''
            }`}
          >
            {additionalData?.map((item, index) => (
              <div key={index} className='pieChartComponent__info--item'>
                {/* ----------------------------------------------- */}
                {item?.svg && (
                  <div className='pieChartComponent__info--item-icon'>
                    <CustomSVGs svg={item?.svg} fillColor={item?.color} />
                  </div>
                )}
                {/* ----------------------------------------------- */}
                {!item?.svg && (
                  <div
                    style={{ backgroundColor: item?.color }}
                    className='pieChartComponent__info--item-dot'
                  ></div>
                )}
                {/* ----------------------------------------------- */}
                <div className='pieChartComponent__info--item-text'>
                  <span>{item.name}:</span>
                  <span>{item.value}</span>
                </div>
                {/* ----------------------------------------------- */}
              </div>
            ))}
          </div>
        )}

        {comment && (
          <p className='pieChartComponent__info--comment'>{comment}</p>
        )}
      </div>
    </div>
  );
};

export default PieChartComponent;
