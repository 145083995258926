import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Refined dummy data
const data = [
  { shippingCost: '$0', conversionRate: 14.8 },
  { shippingCost: '$2.5', conversionRate: 13.2 },
  { shippingCost: '$5', conversionRate: 11.5 },
  { shippingCost: '$7.5', conversionRate: 9.8 },
  { shippingCost: '$10', conversionRate: 8.4 },
  { shippingCost: '$15', conversionRate: 6.5 },
  { shippingCost: '$20', conversionRate: 4.9 },
];

interface Props {
  width: string | number;
  height: number;
  title?: string;
}

export const ShippingCostImpactLineChart = ({
  width,
  height,
  title,
}: Props) => (
  <div className='pieChartComponent'>
    {title && <h2 className='pieChartComponent__title'>{title}</h2>}
    <div className='pieChartComponent__chart'>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='shippingCost' />
          <YAxis tickFormatter={(value) => `${value}%`} />
          <Tooltip formatter={(value) => `${value}%`} />
          <Legend />
          <Line
            type='monotone'
            dataKey='conversionRate'
            stroke='#007bff'
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
);
