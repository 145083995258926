import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CMS } from '../../interfaces/CMS';
import { PSP } from '../../interfaces/PSP';
import { UserState } from '../../interfaces/states/UserState';
import { WebShop } from '../../interfaces/WebShop';

interface OnboardingStagesMapInterface {
  name: string;
  displayTitle: string;
  followedBy: string[];
  stepNumber: number;
}

interface CompanyDetails {
  name: string;
  shop_id: string;
  currency: string;
  shop_slug: string;
  location?: string;
  is_active?: boolean;
  initial?: string;
}

const defaultCompanySettings = {
  theme_color: '',
  font_color: '',
  background_image: '',
  company_logo: '',
  company_icon: '',
  facebook_pixel_id: '',
  tiktok_pixel_id: '',
  google_analytics_id: '',
  default_shipping_cost: '',
  min_free_shipping: '',
  fixed_shipping: '',
  send_monthly_newsletter: '',
  background_color: '',
  fascheckout_ui: '',
  facebook_access_token: '',
  tiktok_test_event_code: '',
  webshop_url: '',
  tiktok_access_token: '',
  setting: '',
  shop_name: '',
  shop_slug: '',
  shop_initials: '',
  currency: '',
};

export const OnboardingStagesMap: {
  [index: string]: OnboardingStagesMapInterface;
} = Object.freeze({
  SigningUp: {
    name: 'SigningUp',
    displayTitle: 'Register',
    followedBy: [
      'ProfileUpdate',
      'SubmittingCompany',
      'IntegratingCMS1',
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection',
      'WebshopTransactions',
    ],
    stepNumber: 1,
  },
  SigningIn: {
    name: 'SigningIn',
    displayTitle: 'SignIn',
    followedBy: [
      'ProfileUpdate',
      'SubmittingCompany',
      'IntegratingCMS1',
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection',
      'WebshopTransactions',
    ],
    stepNumber: 2,
  },
  ProfileUpdate: {
    name: 'ProfileUpdate',
    displayTitle: 'UpdateProfile',
    followedBy: [
      'SubmittingCompany',
      'IntegratingCMS1',
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection',
      'WebshopTransactions',
    ],
    stepNumber: 3,
  },
  SubmittingCompany: {
    name: 'SubmittingCompany',
    displayTitle: 'SetupCompanyDetails',
    followedBy: [
      'IntegratingCMS1',
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection',
      'WebshopTransactions',
    ],
    stepNumber: 4,
  },
  IntegratingCMS1: {
    name: 'IntegratingCMS1',
    displayTitle: 'IntegratingCMS',
    followedBy: [
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection',
      'WebshopTransactions',
    ],
    stepNumber: 5,
  },
  SettingCMSConnection: {
    name: 'SettingCMSConnection',
    displayTitle: 'SettingCMSConnection',
    followedBy: [
      'IntegratingPSP1',
      'SettingPSPConnection',
      'WebshopTransactions',
    ],
    stepNumber: 6,
  },
  IntegratingPSP1: {
    name: 'IntegratingPSP1',
    displayTitle: 'SelectingPaymentProviders',
    followedBy: ['SettingPSPConnection', 'WebshopTransactions'],
    stepNumber: 7,
  },
  SettingPSPConnection: {
    name: 'SettingPSPConnection',
    displayTitle: 'SettingPSPConnection',
    followedBy: ['WebshopTransactions'],
    stepNumber: 8,
  },
  WebshopTransactions: {
    name: 'WebshopTransactions',
    displayTitle: 'WebshopTransactions',
    followedBy: [],
    stepNumber: 9,
  },
});

const makeInitialStagesCompleted = () => {
  const result: { [key: string]: boolean } = {};

  Object.keys(OnboardingStagesMap).forEach((key) => (result[key] = false));

  return result;
};

const initialStagesCompleted: { [key: string]: boolean } = Object.freeze(
  makeInitialStagesCompleted()
);

const initialState: UserState = {
  company: {
    name: '',
    shop_id: '',
    currency: '',
    shop_slug: '',
    location: '',
    is_active: false,
    initial: '',
    webshops: [],
    cms: [],
    psp: [],
    cmsSelected: '',
    pspSelected: '',
    preSelectedCMS_PSP: { preSelectedCMS: '', preSelectedPSP: '' },
    alreadyAddedPSP: [],
    settings: defaultCompanySettings,
  },

  shop: {
    isProductWizardOpen: false,
    shouldShowProductWizard: false,
    hasInitPSP: false,
  },

  stats: {
    ai_tracker_configured_timestamp: '',
  },

  profile: {
    seller_id: '',
    first_name: '',
    last_name: '',
    country_code: '',
    phone: '',
    email: '',
    language: '',
  },

  merchant: {
    id: '',
    merchant_slug: '',
    merchant_name: '',
    fastcheckout_domain: '',
    dashboard_domain: '',
    config: {
      main_brand_color: '',
      secondary_brand_color: '',
      main_brand_hover_color: '',
      header_gradient_color_1: '',
      header_gradient_color_2: '',
      main_btn_color: '',
      main_btn_hover_color: '',
      main_btn_text_color: '',
      main_text_color: '',
      font_family: '',
      merchant_name: '',
      monthly_newsletter_text: '',
      signup_text1: '',
      merchant_favicon: '',
      merchant_icon: '',
      merchant_logo_light: '',
      merchant_logo_dark: '',
      terms_url: '',
      privacy_url: '',
      faq_url: '',
      how_fastcheckout_works_url: '',
      login_image: '',
      signup_image: '',
      is_onboarding_cms_hidden: 'false',
      is_onboarding_psp_hidden: 'false',
    },
  },

  onboarding: {
    onboarding_object: { available_psp: [], available_cms: [] },
    stagesCompleted: initialStagesCompleted,
    currentStage: '',
    availableCMS: [],
    availablePSP: [],
    paymentMethods: {},
    onboarding_completed: false,
  },
  autofillInregrations: null,
  pspConnections: null,
  intercom: {
    app_id: 'ixig6rth',
    isFirstBootComplete: false,
    cms: 'null',
    cmsdatafilled: 'false',
    psp: 'null',
    pspdatafilled: 'false',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserCompanyDetails: (state, action: PayloadAction<CompanyDetails>) => {
      state.company.name = action.payload.name;
      state.company.currency = action.payload.currency;
      state.company.shop_id = action.payload.shop_id;
      state.company.shop_slug = action.payload.shop_slug;
      state.company.location = action.payload.location
        ? action.payload.location
        : '';
      state.company.is_active = action.payload.is_active
        ? action.payload.is_active
        : false;
      state.company.initial = action.payload.initial
        ? action.payload.initial
        : '';
    },

    setUserCompanySettings: (state, action: PayloadAction<any>) => {
      state.company.settings = { ...state.company.settings, ...action.payload };
    },

    updateProfile: (state, action: PayloadAction<any>) => {
      const {
        seller_id,
        first_name,
        last_name,
        country_code,
        phone,
        email,
        language,
      } = action.payload;

      if (seller_id) state.profile.seller_id = seller_id;
      if (first_name) state.profile.first_name = first_name;
      if (last_name) state.profile.last_name = last_name;
      if (country_code) state.profile.country_code = country_code;
      if (phone) state.profile.phone = phone;
      if (email) state.profile.email = email;
      if (language) state.profile.language = language;
    },

    updateMerchant: (state, action: PayloadAction<any>) => {
      const { id, slug, name, settings } = action.payload;

      if (id) state.merchant.id = id;
      if (slug) state.merchant.merchant_slug = slug;
      if (name) state.merchant.merchant_name = name;
      if (settings?.general?.fastcheckout_domain)
        state.merchant.fastcheckout_domain =
          settings?.general?.fastcheckout_domain;
      if (settings?.general?.dashboard_domain)
        state.merchant.dashboard_domain = settings?.general?.dashboard_domain;
      if (settings?.configs) state.merchant.config = settings?.configs;
    },

    updateIntercom: (state, action: PayloadAction<any>) => {
      const { isFirstBootComplete, cms, cmsdatafilled, psp, pspdatafilled } =
        action.payload;
      if (isFirstBootComplete)
        state.intercom.isFirstBootComplete = isFirstBootComplete;
      if (cms) state.intercom.cms = cms;
      if (cmsdatafilled) state.intercom.cmsdatafilled = cmsdatafilled;
      if (psp) state.intercom.psp = psp;
      if (pspdatafilled) state.intercom.pspdatafilled = pspdatafilled;
    },

    addWebshop: (state, action: PayloadAction<WebShop>) => {
      state.company.webshops = state.company.webshops.concat(action.payload);
    },

    updateOnboardingStage: (state, action: PayloadAction<string>) => {
      if (action.payload in OnboardingStagesMap) {
        state.onboarding.currentStage = action.payload;

        // if we go back, erase the data for the stages before
        const followingStages = OnboardingStagesMap[action.payload].followedBy;

        for (const followingStage of followingStages) {
          state.onboarding.stagesCompleted[followingStage] = false;
        }
      }
    },

    getOnboardingObject: (state, action: PayloadAction<any>) => {
      state.onboarding.onboarding_object = action.payload;

      const reuslt = action.payload;

      const psp = reuslt.available_psp?.map((el: object, i: number) => {
        return Object.keys(el)[0].toLowerCase();
      });
      const cms = reuslt.available_cms?.map((el: object, i: number) => {
        return Object.keys(el)[0].toLowerCase();
      });

      state.onboarding.availablePSP = psp;
      state.onboarding.availableCMS = cms;
    },

    setAvailableCMS: (state, action: PayloadAction<any>) => {
      const result = action.payload;
      const cms = result?.map((el: any) => {
        return el?.slug;
      });

      state.onboarding.onboarding_object.available_cms = result;
      state.onboarding.availableCMS = cms;
    },

    setAvailablePSP: (state, action: PayloadAction<any>) => {
      const result = action.payload;
      const psp = result?.map((el: any) => {
        return el?.slug;
      });

      state.onboarding.onboarding_object.available_psp = result;
      state.onboarding.availablePSP = psp;
    },

    // setAvailableCMS: (state, action: PayloadAction<CMS[]>) => {
    //   state.onboarding.availableCMS = action.payload;
    // },

    // setAvailablePSP: (state, action: PayloadAction<PSP[]>) => {
    //   state.onboarding.availablePSP = action.payload;
    // },

    addCMS: (state, action: PayloadAction<CMS>) => {
      state.company.cms = state.company.cms.concat(action.payload);
    },

    addSelectedCMS: (state, action: PayloadAction<string>) => {
      state.company.cmsSelected = action.payload;
    },

    addPSP: (state, action: PayloadAction<PSP>) => {
      state.company.psp = state.company.psp.concat(action.payload);
    },

    addSelectedPSP: (state, action: PayloadAction<string>) => {
      state.company.pspSelected = action.payload;
    },

    removePSP: (state, action: PayloadAction<{ id: string }>) => {
      state.company.psp = state.company.psp.filter(
        (provider) => provider.id !== action.payload.id
      );
    },

    setStageComplete: (
      state,
      action: PayloadAction<{ stage: string; isCompleted: boolean }>
    ) => {
      if (action.payload.stage in OnboardingStagesMap) {
        state.onboarding.stagesCompleted[action.payload.stage] =
          action.payload.isCompleted;
      }
    },

    setPreSelectedCMS_PSP: (
      state,
      action: PayloadAction<{ type: 'cms' | 'psp'; name: string }>
    ) => {
      if (action.payload.type === 'cms') {
        state.company.preSelectedCMS_PSP.preSelectedCMS = action.payload.name;
      } else if (action.payload.type === 'psp') {
        state.company.preSelectedCMS_PSP.preSelectedPSP = action.payload.name;
      }
    },

    completeOnboarding: (state, action: PayloadAction<boolean>) => {
      state.onboarding.onboarding_completed = action.payload;
    },

    setPaymentMethods: (
      state,
      action: PayloadAction<{
        [key: string]: string[];
      }>
    ) => {
      state.onboarding.paymentMethods = action.payload;
    },

    setAutofillInregrations: (state, action: PayloadAction<any>) => {
      state.autofillInregrations = action.payload;
    },

    setPSPConnections: (state, action: PayloadAction<any>) => {
      state.pspConnections = action.payload;
    },

    setAlreadyAddedPSP: (state, action: PayloadAction<any>) => {
      state.company.alreadyAddedPSP = action.payload;
    },

    setIsProductWizardOpen: (state, action: PayloadAction<any>) => {
      state.shop.isProductWizardOpen = action.payload;
    },

    setShouldShowProductWizard: (state, action: PayloadAction<any>) => {
      state.shop.shouldShowProductWizard = action.payload;
    },
    setAiTrackerConfiguredTimestamp: (state, action: PayloadAction<any>) => {
      state.stats.ai_tracker_configured_timestamp = action.payload;
    },

    setHasInitPSP: (state, action: PayloadAction<boolean>) => {
      state.shop.hasInitPSP = action.payload;
    },

    clearUserSlice: (state) => {
      state.company = initialState.company;
      state.shop = initialState.shop;
      state.onboarding = initialState.onboarding;
      state.profile = initialState.profile;
      // state.merchant = initialState.merchant;
      state.intercom = initialState.intercom;
      state.autofillInregrations = initialState.autofillInregrations;
      state.pspConnections = initialState.pspConnections;
    },
  },
});

export const {
  updateProfile,
  addWebshop,
  updateOnboardingStage,
  addCMS,
  addPSP,
  removePSP,
  setStageComplete,
  setAvailableCMS,
  setAvailablePSP,
  setUserCompanyDetails,
  setUserCompanySettings,
  completeOnboarding,
  getOnboardingObject,
  setPaymentMethods,
  setAutofillInregrations,
  setPSPConnections,
  addSelectedCMS,
  addSelectedPSP,
  updateIntercom,
  clearUserSlice,
  setAlreadyAddedPSP,
  setIsProductWizardOpen,
  setShouldShowProductWizard,
  setPreSelectedCMS_PSP,
  setHasInitPSP,
  updateMerchant,
  setAiTrackerConfiguredTimestamp,
} = userSlice.actions;
export const userStateReducer = userSlice.reducer;
