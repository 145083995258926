import { motion } from 'framer-motion';
import store from '../../store/store';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { onFormSubmitedFailed } from '../../components/generic/common-toasts';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import {
  OnboardingStagesMap,
  setStageComplete,
} from '../../store/reducers/userSlice';
import { RootState } from '../../store/store';
import './onboarding-page.css';
import '../../styles/components/onboarding/profileUpdate.scss';
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
import NormalSelectInput from '../../ui/inputs/NormalSelectInput';
import { useDispatch } from 'react-redux';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { useTranslation } from 'react-i18next';
import generateErrorMessage from '../../lib/helper-functions/generateErrorMessage';
import { submitLogToBugsnag } from '../../lib/api/log';
import {
  SHOP_API,
  STATISTICS_BY_SHOP_API,
} from '../../lib/constants/central-api';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../../lib/constants/route';
import { setIsOnboardingCompleteBannerOpen } from '../../store/reducers/uiSlice';
import { setRunInitOnFirstLoad } from '../../store/reducers/appSlice';
import NormalInput from '../../ui/inputs/NormalInput';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import FooterLinks from '../../components/footer/FooterLinks';

const WebshopTransactions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);
  const whiteLabelRedirectPath = useSelector(
    (state: RootState) => state.auth.authorization.whiteLabelRedirectPath
  );
  const shopSlug = store.getState().user?.company?.shop_slug;
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  const slug = store.getState().user?.company?.shop_slug;

  const transactionsArr = [
    { OptionValue: '0-2500', OptionName: '0-2500' },
    { OptionValue: '2500-10000', OptionName: '2500-10000' },
    { OptionValue: '10000-25000', OptionName: '10000-25000' },
    { OptionValue: '25000+', OptionName: '25000+' },
  ];
  const [webshopURL, setWebshopURL] = useState<string>('');
  const [numberOfTransactions, setNumberOfTransactions] = useState('0-2500');

  const dispatch = useDispatch();
  const [errors, setErrors] = useState<any>();
  const accessToken = store.getState().auth.authorization.accessToken;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const submitTransactionData = async () => {
    const sendingData = JSON.stringify({
      additional_stats: {
        monthly_transactions: numberOfTransactions,
      },
    });

    const requestInfo: RequestInfo = {
      url: STATISTICS_BY_SHOP_API(shopSlug),
      method: 'PUT',
      body: sendingData,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'ONBOARDING_WEB_SHOP_TRANSACTIONS';
    const customMsg = 'Webshop Transactions Update Successfull.';

    const res: any = await asyncHandler(requestInfo, actionType, customMsg);

    const response = res.data;

    if (res.actionType === actionType && response.success === true) {
      return true;
    } else {
      setErrors(generateErrorMessage(response));
      onFormSubmitedFailed(generateErrorMessage(response));
      return false;
    }
  };

  const submitWebshopData = async () => {
    const sendingData = {
      webshop_url: webshopURL,
    };

    const requestInfo: RequestInfo = {
      url: SHOP_API + `/${slug}/setting/shop-general`,
      method: 'PUT',
      body: applicationWWWXRLEncodedBodyBuilder(sendingData),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'ONBOARDING_WEB_SHOP_URL';
    const customMsg = t('SettingsUpdatedSuccess-msg');

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res.data;

    if (res.actionType === actionType && response.success === true) {
      return true;
    } else {
      setErrors(generateErrorMessage(response));
      onFormSubmitedFailed(generateErrorMessage(response));
      return false;
    }
  };

  async function onSubmit(e: any) {
    e.preventDefault();
    try {
      const res1 = await submitTransactionData();
      const res2 = await submitWebshopData();

      if (res1 && res2) {
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.WebshopTransactions.name,
            isCompleted: true,
          })
        );

        //hit init
        dispatch(setRunInitOnFirstLoad(true));
        //go to home page
        dispatch(setIsOnboardingCompleteBannerOpen(true));
        const timmer = setTimeout(() => {
          dispatch(setIsOnboardingCompleteBannerOpen(false));
          clearTimeout(timmer);
        }, 2000);
        if (isWhiteLabel) {
          if (whiteLabelRedirectPath && whiteLabelRedirectPath !== '') {
            navigate(whiteLabelRedirectPath);
          } else {
            navigate(HOME_ROUTE);
          }
        } else {
          navigate(HOME_ROUTE);
        }
      }
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  return (
    <>
      <motion.div
        {...pageSlideOutForward}
        key='onboarding-company-details-page'
        className='enterCompanyDetails animate-fade_in_from_right'
      >
        <div className='enterCompanyDetails__top'></div>
        <div className='enterCompanyDetails__container'>
          <div className='enterCompanyDetails__container--top'>
            <h1>{t('OnboardingWebshopTransactionsH1')}</h1>
            {/* <h2>{t('OnboardingWebshopTransactionsH2')}</h2> */}
          </div>

          <form
            onSubmit={onSubmit}
            id='onboarding-WebshopTransactions'
            className='enterCompanyDetails__container--center'
          >
            <NormalSelectInput
              defaultValue={numberOfTransactions}
              setSelect={setNumberOfTransactions}
              selectOptionArr={transactionsArr}
              // placeholder={t('Language')}
              id={'transaction-number'}
            />

            <NormalInput
              id='onboarding-WebshopTransactions-webshop-url'
              value={webshopURL}
              changeListeners={[(e) => setWebshopURL(e.target.value)]}
              focusListeners={[]}
              placeholder={t('WebshopURL')}
              classes='lg:w-[13rem]'
              required={true}
              /* pattern='^[A-Za-z0-9 ]{1,100}$' */
              title={t('Expected-URL-Format-validation')}
              innerPlaceholder={'https://example.com'}
              type='url'
            />
          </form>
          <div className='enterCompanyDetails__container--bottom'>
            <MainPurpleButton
              value={t('Continue')}
              type='submit'
              form='onboarding-WebshopTransactions'
              /* classes=' w-[120px] rounded-full h-12 text-white text-[12.6px]' */
            />
          </div>

          <div className='enterCompanyDetails__container--footer'>
            <FooterLinks />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default WebshopTransactions;
