import { AnimatePresence, motion } from 'framer-motion';
import { CMS } from '../../interfaces/CMS';
import { addSelectedCMS } from '../../store/reducers/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import '../../styles/components/selection/cmsSelector.scss';
import { useTranslation } from 'react-i18next';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';

interface OptionProps {
  allAvailableCMSServices: any[];
  singleCMS: CMS;
}

const CMSOption = ({ singleCMS, allAvailableCMSServices }: OptionProps) => {
  const selectedCMS = useSelector(
    (state: RootState) => state.user.company.cmsSelected
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();

  let slug: string;
  if (typeof singleCMS === 'string') {
    slug = singleCMS;
  } else {
    slug = singleCMS?.slug;
  }

  const staticURL = useSelector((state: RootState) => state.app.staticURL);

  const currentOrigin = window.location.origin;

  const updated_available_cms = [
    ...allAvailableCMSServices,
    {
      id: 88887,
      name: 'Other',
      description: null,
      slug: 'other',
      icon: `${currentOrigin}/cms-psp-logos/other-option.png`,
    },
    {
      id: 88888,
      name: 'NoCMS',
      description: null,
      slug: 'nocms',
      icon: `${currentOrigin}/cms-psp-logos/do-not-have.png`,
    },
  ];

  const currentCMS: any = updated_available_cms?.find((item: any) => {
    return item?.slug === slug;
  });

  const currentValues: any = currentCMS;
  let currentIcon: string;
  if (
    currentValues?.slug === 'shopify' ||
    currentValues?.slug === 'bigcommerce' ||
    currentValues?.slug === 'other' ||
    currentValues?.slug === 'nocms'
  ) {
    currentIcon = currentValues?.icon;
  } else {
    currentIcon = `${staticURL}/small/${currentValues?.icon}`;
  }

  const renderH1 = () => {
    let headingName;
    if (slug === 'nocms') {
      headingName = t('NoCMS');
    } else if (slug === 'other') {
      headingName = t('OtherCMS');
    } else if (slug === 'bigcommerce') {
      headingName = 'BigCommerce';
    } else if (slug === 'woocommerce') {
      headingName = 'WooCommerce';
    } else if (slug === 'magento') {
      headingName = 'Magento';
    } else {
      headingName = makeFirstLetterCapital(slug);
    }

    return headingName;
  };

  return (
    <div
      onClick={() => {
        dispatch(addSelectedCMS(slug));
      }}
      className={'cmsSelector__option'}
    >
      <div
        className={
          'cmsSelector__option--content border-[#e5e5e5]' +
          (selectedCMS === slug ? ' border-[rgb(20,184,166)]' : '')
        }
      >
        <div className='cmsSelector__option--content-left'>
          {/* {name !== 'other' && ( */}
          <div className='cmsSelector__option--content-left-img'>
            <img
              src={currentIcon} //! Always make sure the png name is identical to the API value.
              alt={`${slug} icon`}
            />
          </div>
          {/*    )} */}
          <div className='cmsSelector__option--content-left-text'>
            {/* h1 */}
            <h1>{renderH1()}</h1>

            {/* h3 */}
            {/* {name === 'magento' && <h3>{t('ComingSoon')}</h3>} */}
            {slug === 'shopify' && <h3>{t('ComingSoon')}</h3>}
            {slug === 'bigcommerce' && <h3>{t('ComingSoon')}</h3>}
          </div>
        </div>
      </div>
      {selectedCMS === slug && (
        <motion.div className='cmsSelector__option--check  '>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1.8rem'
            height='1.8rem'
            viewBox='0 0 24 24'
            fill='none'
            stroke='teal'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='feather feather-check absolute '
          >
            <polyline points='20 6 9 17 4 12'></polyline>
          </svg>
        </motion.div>
      )}
    </div>
  );
};

/* ---------------------------CMSSelector--------------------------- */
type props = {
  options: CMS[];
};

const CMSSelector: React.FC<props> = ({ options = [] }) => {
  const updatedOptions = [
    ...options,
    {
      id: 88887,
      name: 'Other',
      description: null,
      slug: 'other',
      icon: '',
    },
    {
      id: 88888,
      name: 'NoCMS',
      description: null,
      slug: 'nocms',
      icon: '',
    },
  ];

  return (
    <div className='cmsSelector'>
      <div className='multiple-item-selector-inner cmsSelector__wrapper'>
        <AnimatePresence>
          <motion.div className='cmsSelector__content'>
            {updatedOptions?.map((option, key) => (
              <CMSOption
                key={key}
                allAvailableCMSServices={options}
                singleCMS={option}
              />
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default CMSSelector;
