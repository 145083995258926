import { t } from 'i18next';

interface InputData {
  name: string;
  type: string;
  label: string;
  default: string;
  is_active: boolean;
  is_required: boolean;
  is_hidden?: boolean;
}

const paymentStatusInputData: InputData[] = [
  {
    name: 'statusPaymentTrackingMechanism',
    type: 'text',
    label: t('TrackingMechanism'),
    default: '',
    is_active: false,
    is_required: false,
    is_hidden: true,
  },
  {
    name: 'statusCompletePaymentCSSElement',
    type: 'text',
    label: t('PaymentCSSElement', {
      status: 'Complete',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCompletePaymentPreDefinedText',
    type: 'text',
    label: t('PaymentPreDefinedText', {
      status: 'Complete',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCompletePaymentURLparam',
    type: 'text',
    label: t('PaymentURLparam', {
      status: 'Complete',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCompletePaymentUniqueURL',
    type: 'text',
    label: t('PaymentUniqueURL', {
      status: 'Complete',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusFailedPaymentCSSElement',
    type: 'text',
    label: t('PaymentCSSElement', {
      status: 'Failed',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusFailedPaymentPreDefinedText',
    type: 'text',
    label: t('PaymentPreDefinedText', {
      status: 'Failed',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusFailedPaymentURLparam',
    type: 'text',
    label: t('PaymentURLparam', {
      status: 'Failed',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusFailedPaymentUniqueURL',
    type: 'text',
    label: t('PaymentUniqueURL', {
      status: 'Failed',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusExpiredPaymentCSSElement',
    type: 'text',
    label: t('PaymentCSSElement', {
      status: 'Expired',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusExpiredPaymentPreDefinedText',
    type: 'text',
    label: t('PaymentPreDefinedText', {
      status: 'Expired',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusExpiredPaymentURLparam',
    type: 'text',
    label: t('PaymentURLparam', {
      status: 'Expired',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusExpiredPaymentUniqueURL',
    type: 'text',
    label: t('PaymentUniqueURL', {
      status: 'Expired',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCancelPaymentCSSElement',
    type: 'text',
    label: t('PaymentCSSElement', {
      status: 'Cancel',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCancelPaymentPreDefinedText',
    type: 'text',
    label: t('PaymentPreDefinedText', {
      status: 'Cancel',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCancelPaymentURLparam',
    type: 'text',
    label: t('PaymentURLparam', {
      status: 'Cancel',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCancelPaymentUniqueURL',
    type: 'text',
    label: t('PaymentUniqueURL', {
      status: 'Cancel',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
];

export const initialInputData: InputData[] = [
  {
    name: 'bodyElement',
    type: 'text',
    label: 'Page Body',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productNameElement',
    type: 'text',
    label: 'Product Name',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productDescriptionElement',
    type: 'text',
    label: 'Product Description',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productImageCarouselElement',
    type: 'text',
    label: 'Product Image Carousel',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productMainImageElement',
    type: 'text',
    label: 'Product Main Image',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productSizeElement',
    type: 'text',
    label: 'Product Size',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productTagsElement',
    type: 'text',
    label: 'Product Tags',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productPriceElement',
    type: 'text',
    label: 'Product Price',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productQuantityElement',
    type: 'text',
    label: 'Product QTY',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productSaleElement',
    type: 'text',
    label: 'Product Sale / Discount',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productShippingElement',
    type: 'text',
    label: 'Shipping Cost',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productDeliveryElement',
    type: 'text',
    label: 'Delivery Date & Time',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productVariantsElement',
    type: 'text',
    label: 'Product Variants',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'cartTotalElement',
    type: 'text',
    label: 'Cart Total',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'cartCountElement',
    type: 'text',
    label: 'Cart Items Count',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'checkoutFormElement',
    type: 'text',
    label: 'Checkout Form',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'payButtonElement',
    type: 'text',
    label: 'Pay Button',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'addToCartButtonElement',
    type: 'text',
    label: 'Add to Cart Button',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'removeFromCartButtonElement',
    type: 'text',
    label: 'Remove from Cart Button',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'viewCartButtonElement',
    type: 'text',
    label: 'View Cart Button',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'goToCheckoutButtonElement',
    type: 'text',
    label: 'Go to Checkout Button',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'addToFavoritesButtonElement',
    type: 'text',
    label: 'Add to Favorites Button',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'removeFromFavoritesButtonElement',
    type: 'text',
    label: 'Remove from Favorites Button',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'addToCompareButtonElement',
    type: 'text',
    label: 'Add to Compare Button',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'customerReviewsButtonElement',
    type: 'text',
    label: 'Customer Reviews Button',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productViewButtonElement',
    type: 'text',
    label: 'Product View Button',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'bannerElement',
    type: 'text',
    label: 'Banner',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'headerElement',
    type: 'text',
    label: 'Header',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'selectedPaymentMethodElement',
    type: 'text',
    label: 'Selected Payment Method',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'currencyElement',
    type: 'text',
    label: 'Currency',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'countryCodeInputElement',
    type: 'text',
    label: 'Country Code Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'phoneNumberInputElement',
    type: 'text',
    label: 'Phone Number Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'firstNameInputElement',
    type: 'text',
    label: 'First Name Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'lastNameInputElement',
    type: 'text',
    label: 'Last Name Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'emailInputElement',
    type: 'text',
    label: 'Email Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingHouseNoInputElement',
    type: 'text',
    label: 'Billing House No Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingStreetInputElement',
    type: 'text',
    label: 'Billing Street Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingStreet2InputElement',
    type: 'text',
    label: 'Billing Street 2 Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingCityInputElement',
    type: 'text',
    label: 'Billing City Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingProvinceInputElement',
    type: 'text',
    label: 'Billing Province Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingPostalCodeInputElement',
    type: 'text',
    label: 'Billing Postal Code Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingCountryInputElement',
    type: 'text',
    label: 'Billing Country Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingHouseNoInputElement',
    type: 'text',
    label: 'Shipping House No Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingStreetInputElement',
    type: 'text',
    label: 'Shipping Street Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingStreet2InputElement',
    type: 'text',
    label: 'Shipping Street 2 Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingCityInputElement',
    type: 'text',
    label: 'Shipping City Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingProvinceInputElement',
    type: 'text',
    label: 'Shipping Province Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingPostalCodeInputElement',
    type: 'text',
    label: 'Shipping Postal Code Input',
    default: '',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingCountryInputElement',
    type: 'text',
    label: 'Shipping Country Input',
    default: '',
    is_active: true,
    is_required: false,
  },

  //* Attaching the payment status input data
  ...paymentStatusInputData,
];

export function transformInputData(inputArray: InputData[]): {
  [key: string]: string;
} {
  // Check if the array is empty or undefined
  if (!inputArray?.length) {
    return {};
  }

  // Create the resulting object
  const result: { [key: string]: string } = {};

  // Iterate over each item in the array
  inputArray.forEach((item) => {
    result[item.name] = item.default;
  });

  return result;
}

//--------------------usage------------------------
// const transformedObject = transformInputData(initialInputData);
// console.log(transformedObject);
// Output:
// {
//     bodyElement: '',
//     productNameElement: '',
//     productPriceElement: '',
//     productDescriptionElement: '',
//     productQuantityElement: '',
//     productSaleElement: '',
//     productShippingElement: '',
//     productDeliveryElement: '',
//     productVariantsElement: '',
//     cartTotalElement: '',
//     checkoutFormElement: '',
//     payButtonElement: ''
// }
