import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

// Dummy data for European countries
const data = [
  { country: 'Germany', purchases: 1200 },
  { country: 'France', purchases: 850 },
  { country: 'Italy', purchases: 700 },
  { country: 'Spain', purchases: 600 },
  { country: 'Netherlands', purchases: 500 },
  { country: 'Sweden', purchases: 300 },
  { country: 'Belgium', purchases: 250 },
  { country: 'Poland', purchases: 200 },
];

interface Props {
  width: string | number;
  height: number;
  title?: string;
}

const ProductPurchaseBarChart = ({ width, height, title }: Props) => {
  return (
    <div className='pieChartComponent'>
      {title && <h2 className='pieChartComponent__title'>{title}</h2>}
      <div className='pieChartComponent__chart'>
        <ResponsiveContainer width={width} height={height}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, bottom: 20, left: 20 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='country' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey='purchases' fill='#8884d8' />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ProductPurchaseBarChart;
