import { useEffect, useState } from 'react';
import '../../styles/pages/login.scss';
import { useNavigate } from 'react-router-dom';
import store from '../../store/store';
import { setAccessToken } from '../../store/reducers/authSlice';
import { useTranslation } from 'react-i18next';
import LoginInput from '../../ui/inputs/LoginInput';
import MessageSVG from '../../ui/customSVGs/MessageSVG';
import LockSVG from '../../ui/customSVGs/LockSVG';
import ToggleSwitch from '../../ui/buttons/ToggleSwitch';
import {
  GOOGLE_LOGIN_ENDPOINT,
  TWO_FACTOR_AUTHENTICATION_LOGIN_ENDPOINT,
} from '../../lib/constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { fetchAppInit } from '../../lib/api/app-init';
import Lottie from 'lottie-react';
import lottieBanner from '../../assets/json/lottie_animation/click_1.json';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { setIsInitSuccess, setMessage } from '../../store/reducers/appSlice';
import {
  applicationJSONBodyBuilder,
  applicationWWWXRLEncodedBodyBuilder,
} from '../../lib/utils/body-builder';
import moment from 'moment';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import SlideFromBottom from '../../ui/sliders/SlideFromBottom';
import { OTPCodeInput } from '../../ui/inputs/OTPCodeInput';
import RenderLoginPageLogo from './RenderLoginPageLogo';
import { LOGIN_API } from '../../lib/constants/central-api';
import PasswordInput from '../../ui/inputs/PasswordInput';
import { submitLogToBugsnag } from '../../lib/api/log';
import { HOME_ROUTE } from '../../lib/constants/route';
import FooterLinks from '../../components/footer/FooterLinks';

const LoginPage = () => {
  const { t } = useTranslation();
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [rememberFlag, setRememberFlag] = useState<boolean>(false);
  const [googleLoading, setGoogleLoading] = useState<boolean>(false);
  const [isSliderOpen, setIsSliderOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const authToken = store.getState().auth.authorization.accessToken;

  const [secretKey2fa, setSecretKey2fa] = useState('');
  const [userId2fa, setUseId2fa] = useState('');

  const navigate = useNavigate();

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const merchantName = useSelector(
    (state: RootState) => state.user?.merchant?.config?.merchant_name
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);

  useEffect(() => {
    setIsDisabled(
      emailAddress === '' ||
        password === '' ||
        !/[^ ]{8,20}/.test(password) ||
        !/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/.test(emailAddress)
        ? true
        : false
    );
  }, [emailAddress, password]);

  const normalLoginProcess = (response: any) => {
    store.dispatch(setIsInitSuccess(false));
    const token_type = 'Bearer';
    const { access_token } = response?.data;

    if (access_token && access_token !== '') {
      store.dispatch(
        setAccessToken({
          sellerToken: access_token,
          type: token_type,
          expiresOn:
            rememberFlag === true
              ? moment().add(21, 'days')
              : moment().add(1, 'days'),
        })
      );
    }
    store.dispatch(
      setMessage({
        message: t('LoginSuccess-msg'),
        messageType: 'success',
      })
    );
    navigate(HOME_ROUTE);

    //make init call to get all info about logged-In user
    fetchAppInit();
  };

  async function handleClick(e: any) {
    e.preventDefault();
    setIsDisabled(true);
    try {
      const requestInfo: RequestInfo = {
        url: LOGIN_API,
        method: 'POST',
        body: applicationJSONBodyBuilder({
          email: emailAddress,
          password,
          remember_me: rememberFlag ?? false,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      const actionType = 'LOGIN_VERIFICATION';
      const customMsg = t('LoginSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        if (response?.result?.secret_key) {
          //TODO 2FA Auth - Central API not supported yet
          setSecretKey2fa(response?.result?.secret_key);
          setUseId2fa(response?.result?.user_id);
          setIsSliderOpen(true);
        } else {
          normalLoginProcess(response);
        }
      } else {
        setError(response.message);
        setIsDisabled(false);
        /*  onFormSubmitedFailed(response.message);*/

        // console.log(response);
        /* onFormSubmitedFailed(generateErrorMessage(response)); */
      }
    } catch (exception) {
      setIsDisabled(false);
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  /* =============2FA Auth stuff start ================ */
  async function OTPSubmitHandler(otpCode: string) {
    try {
      const sendingData = {
        otp_code: otpCode,
        Tfa_secret: secretKey2fa,
        user_id: userId2fa,
      };

      const requestInfo: RequestInfo = {
        url: TWO_FACTOR_AUTHENTICATION_LOGIN_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'SENDING-2FA-OTP';
      const customMsg = t('2fa-VerifiedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        normalLoginProcess(response);
        setIsSliderOpen(false);
      }
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  /* =============2FA Auth stuff end ================ */

  //TODO Google SSO - Central API not supported yet
  async function googleSignIn(e: any) {
    e.preventDefault();
    setGoogleLoading(true);
    try {
      const requestInfo: RequestInfo = {
        url: GOOGLE_LOGIN_ENDPOINT,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      const actionType = 'GOOGLE_LOGIN_VERIFICATION';
      const customMsg = 'Redirecting to Google Login.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        const { google_url } = response.result;
        window.open(`${google_url}`, '_self');
      } else {
        setGoogleLoading(false);
        setError(response.message);
        /*  onFormSubmitedFailed(response.message);*/

        // console.log(response);
        /* onFormSubmitedFailed(generateErrorMessage(response)); */
      }
    } catch (exception) {
      setGoogleLoading(false);
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  if (!authToken.sellerToken) {
    return (
      <div
        /* key={2}
        {...pageSlideInBackward}
        {...pageSlideOutBackward} */
        className='login'
      >
        <div className='login__left flex-row'>
          {/* <img src={bg} alt='login-background' /> */}
          <div className='login__left-lottie'>
            {merchantName !== 'AdUp' && merchantTheme?.login_image ? (
              <div className='login__left-lottie-img'>
                <img
                  src={`${staticURL}/medium/${merchantTheme?.login_image}`}
                  alt='login-pic'
                />
              </div>
            ) : (
              <Lottie animationData={lottieBanner} loop={true} />
            )}
          </div>
        </div>

        <form onSubmit={handleClick} className='login__right'>
          <div className='login__right--top'>
            <RenderLoginPageLogo
              staticURL={staticURL}
              merchantTheme={merchantTheme}
              isDarkMode={isDarkMode}
            />
            {/* <img className='login__right--top-mode' src={sun} alt='sun' /> */}
          </div>

          <div className='login__right--center'>
            <div className='login__right--center-titles'>
              <h1>{t('SignIn')}</h1>
              <p>{t('SignInSubText')}</p>
            </div>
            <div className='login__right--center-inputs'>
              <LoginInput
                icon={<MessageSVG fill='#cecece' />}
                id='login-form-email'
                value={emailAddress}
                type='email'
                changeListeners={[(e) => setEmailAddress(e.target.value)]}
                focusListeners={[]}
                placeholder={t('EmailAddress')}
                required={true}
                pattern='^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$'
                title={t('Email-validation')}
              />
              <PasswordInput
                icon={<LockSVG fill='#cecece' />}
                id='login-form-password'
                value={password}
                changeListeners={[(e) => setPassword(e.target.value)]}
                focusListeners={[]}
                placeholder={t('Password')}
                required={true}
                pattern='[^ ]{8,20}'
                title={t('Password-validation')}
              />
              {/* //? Central API doesn't support forgot-password yet
              <div
                className='login__right--center-links'
                style={{ marginTop: '-10px' }}
              >
                <span onClick={() => navigate('/forgot-password')}>
                  {t('ForgotPassword')}
                </span>
              </div> */}
            </div>
            <div className='login__right--center-links'>
              <ToggleSwitch
                title={t('RememberMe')}
                onClick={(e: any) => {
                  setRememberFlag(e);
                }}
                defaultChecked={rememberFlag}
              />
            </div>
          </div>
          <div className='login__right--bottom'>
            <MainSquareButton
              value={t('LoginNow')}
              disabled={isDisabled}
              type='submit'
              classes='mainSquareButton-extra-1'
            />
            <h2>
              {t('Or')}{' '}
              <span onClick={() => navigate('/register')}>{t('Create')}</span>{' '}
              {t('An-Account')}
            </h2>
          </div>

          {/* <div className='login__right--other'>
            <img
              src={googleButton}
              alt='googleButton'
              onClick={!googleLoading ? googleSignIn : () => {}}
            />
          </div> */}

          <div className='login__right--footer'>
            <FooterLinks />
          </div>
        </form>

        {isSliderOpen && (
          <SlideFromBottom
            hideSegment={() => {
              setIsSliderOpen(false);
            }}
          >
            <div className='login__otp'>
              <h1>{t('EnterOTP')}</h1>
              <OTPCodeInput
                numberOfFields={6}
                onComplete={(code: string) => {
                  OTPSubmitHandler(code);
                }}
              />
            </div>
          </SlideFromBottom>
        )}
      </div>
    );
  } else {
    window.location.assign('/');
    return null;
  }
};

export default LoginPage;
