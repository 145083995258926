import React from 'react';
import { ResponsiveContainer } from 'recharts';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from 'recharts';

// Realistic dummy data
const data = [
  { colorName: 'Primary Blue', colorCode: '#007bff', usage: 45 },
  { colorName: 'Gray', colorCode: '#6c757d', usage: 25 },
  { colorName: 'Success Green', colorCode: '#28a745', usage: 15 },
  { colorName: 'Danger Red', colorCode: '#dc3545', usage: 8 },
  { colorName: 'Warning Yellow', colorCode: '#ffc107', usage: 5 },
  { colorName: 'Info Blue', colorCode: '#17a2b8', usage: 2 },
];

interface Props {
  width: string | number;
  height: number;
  title?: string;
}

const ColorUsageScatterChart = ({ width, height, title }: Props) => {
  return (
    <div className='pieChartComponent'>
      {title && <h2 className='pieChartComponent__title'>{title}</h2>}
      <div className='pieChartComponent__chart'>
        <ResponsiveContainer width={width} height={height}>
          <ScatterChart margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
            <CartesianGrid />
            <XAxis type='number' dataKey='usage' name='Usage' unit='%' />
            <YAxis type='category' dataKey='colorName' name='Color' />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Scatter name='Colors' data={data} fill='#8884d8'>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.colorCode} />
              ))}
            </Scatter>
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ColorUsageScatterChart;
