import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import '../../styles/components/onboarding/onboardingNavProgressBar.scss';
import ProgressBar from '../../ui/progressBar/ProgressBar';

const OnboardingNavProgressBar = () => {
  const onboardingState = useSelector(
    (state: RootState) => state.user.onboarding
  );
  const merchantConfig = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const allSteps = Object?.keys(onboardingState?.stagesCompleted).length - 2;

  const [progress, setProgress] = useState(0);

  const getTotalSteps = () => {
    if (
      merchantConfig.is_onboarding_psp_hidden === 'true' &&
      merchantConfig.is_onboarding_cms_hidden === 'true'
    ) {
      return 3;
    }
    /* --------------------- */
    if (
      merchantConfig.is_onboarding_psp_hidden === 'true' &&
      merchantConfig.is_onboarding_cms_hidden !== 'true'
    ) {
      return 5;
    }
    /* --------------------- */
    if (
      merchantConfig.is_onboarding_psp_hidden !== 'true' &&
      merchantConfig.is_onboarding_cms_hidden === 'true'
    ) {
      return 5;
    }
    /* --------------------- */
    return allSteps;
  };

  useEffect(() => {
    if (onboardingState.currentStage === 'ProfileUpdate') {
      setProgress(1);
    }
    if (onboardingState.currentStage === 'SubmittingCompany') {
      setProgress(2);
    }
    if (onboardingState.currentStage === 'IntegratingCMS1') {
      setProgress(3);
    }
    if (onboardingState.currentStage === 'SettingCMSConnection') {
      setProgress(4);
    }
    if (onboardingState.currentStage === 'IntegratingPSP1') {
      setProgress(5);
    }
    if (onboardingState.currentStage === 'SettingPSPConnection') {
      setProgress(6);
    }
    if (onboardingState.currentStage === 'WebshopTransactions') {
      if (
        merchantConfig.is_onboarding_psp_hidden === 'true' &&
        merchantConfig.is_onboarding_cms_hidden === 'true'
      ) {
        setProgress(3);
      } else if (
        merchantConfig.is_onboarding_psp_hidden === 'true' &&
        merchantConfig.is_onboarding_cms_hidden !== 'true'
      ) {
        setProgress(5);
      } else if (
        merchantConfig.is_onboarding_psp_hidden !== 'true' &&
        merchantConfig.is_onboarding_cms_hidden === 'true'
      ) {
        setProgress(5);
      } else {
        setProgress(7);
      }
    }
  }, [onboardingState]);

  return (
    <div className='onboardingNavProgressBar'>
      <ProgressBar currentLevel={progress} steps={getTotalSteps()} />
    </div>
  );
};

export default OnboardingNavProgressBar;
