import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { onFormSubmitedFailed } from '../../components/generic/common-toasts';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
// import { sendSelectedPSP } from '../../lib/api/post-onboarding-data';
import {
  completeOnboarding,
  OnboardingStagesMap,
  setStageComplete,
  updateIntercom,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import { RootState } from '../../store/store';
import './onboarding-page.css';
import '../../styles/components/onboarding/enterCMSConnectionDetails.scss';
import NormalInput from '../../ui/inputs/NormalInput';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
import { useTranslation } from 'react-i18next';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';
import { setRunInitOnFirstLoad } from '../../store/reducers/appSlice';
import add_psp_api_call from '../../lib/api/psp/add-psp';
import { getCustomInputFields } from '../../lib/helper-functions/getCustomInputFields';
import { submitLogToBugsnag } from '../../lib/api/log';
import FooterLinks from '../../components/footer/FooterLinks';

const EnterPSPConnectionDetails = () => {
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const { t } = useTranslation();

  const [pspInputs, setPSPInputs] = useState<string[]>([]);
  const [pspInputValues, setPSPInputValues] = useState<any>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedPSP = useSelector(
    (state: RootState) => state.user.company.pspSelected
  );
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);
  const whiteLabelRedirectPath = useSelector(
    (state: RootState) => state.auth.authorization.whiteLabelRedirectPath
  );
  /* ------other option state ----------*/
  const optionType = 'psp';
  const [customOption, setCustomOption] = useState('');

  /* ================================================================================= */
  useEffect(() => {
    const [currentPSP_inputs_array, currentPSP_inputs_object] =
      getCustomInputFields(selectedPSP, onboarding_object?.available_psp);

    setPSPInputs(currentPSP_inputs_array);
    setPSPInputValues(currentPSP_inputs_object);
  }, [selectedPSP]);
  /* ================================================================================= */

  /* async function onContinue(e: any) {
    e.preventDefault();
    if (selectedPSP === 'other') {
      const res: any = await submitCustomOption('psp', customOption);
      if (res) {
        const statusRes = await submitOnboardingStatus('psp', customOption);
        if (statusRes) {
          dispatch(setIsOnboardingCompleteBannerOpen(true));
          const statusRes2 = await submitOnboardingStatus(
            'onboarding_completed',
            'true'
          );
          if (statusRes2) {
            dispatch(
              updateIntercom({
                psp: customOption,
              })
            );
            dispatch(
              setStageComplete({
                stage: OnboardingStagesMap.SettingPSPConnection.name,
                isCompleted: true,
              })
            );
            dispatch(completeOnboarding(true));
            navigate('/integrations');
          } else {
            dispatch(setIsOnboardingCompleteBannerOpen(false));
          }
        }
      }
    } else {
      try {
        const accessToken = store.getState().auth.authorization.accessToken;

        const sendingData = {
          onboard_type: pspName,
          psp_module: selectedPSP,
          shop_id: shopId,
          payment_details: pspInputValues,
        };

        const requestInfo: RequestInfo = {
          url: ONBOARDING_ENDPOINT,
          method: 'POST',
          body: applicationWWWXRLEncodedBodyBuilder({
            onboarding_data: JSON.stringify(sendingData),
          }),
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/x-www-form-urlencoded',
            Authorization: accessToken.type + ' ' + accessToken.sellerToken,
          },
        };

        const actionType = 'ONBOARDING_PSP_CONNECTION';
        const customMsg = 'PSP Connection Successful.';

        const res: any = await asyncHandler(requestInfo, actionType, customMsg);

        const response = res.data;

        if (res.actionType === actionType && response.success === true) {
          const statusRes = await submitOnboardingStatus(
            'onboarding_completed',
            'true'
          );

          if (statusRes) {
            dispatch(
              updateIntercom({
                pspdatafilled: 'true',
              })
            );
            dispatch(
              setStageComplete({
                stage: OnboardingStagesMap.SettingPSPConnection.name,
                isCompleted: true,
              })
            );
            dispatch(completeOnboarding(true));
            // navigate(HOME_ROUTE);
            navigate('/integrations');
          }
        }
      } catch (exception) {
        console.log('Error during authentication request');
      }
    }
  } */

  const navigateToNextStage = () => {
    if (isWhiteLabel) {
      if (whiteLabelRedirectPath && whiteLabelRedirectPath !== '') {
        navigate(whiteLabelRedirectPath);
      } else {
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.WebshopTransactions.name)
        );
      }
    } else {
      dispatch(
        updateOnboardingStage(OnboardingStagesMap.WebshopTransactions.name)
      );
    }
  };

  //TODO: When the submitOnboardingStatus ENDPOINT is completed, delete this function and uncomment the above function and use it.
  async function onContinue(e: any) {
    e.preventDefault();
    if (selectedPSP === 'other') {
      // const res: any = await submitCustomOption('psp', customOption);
      if (true) {
        // const statusRes = await submitOnboardingStatus('psp', customOption);
        if (true) {
          if (true) {
            dispatch(
              updateIntercom({
                psp: customOption,
              })
            );
            dispatch(
              setStageComplete({
                stage: OnboardingStagesMap.SettingPSPConnection.name,
                isCompleted: true,
              })
            );
            dispatch(completeOnboarding(true));
            navigateToNextStage();
          }
        }
      }
    } else {
      try {
        const res: boolean = await add_psp_api_call(
          selectedPSP,
          pspInputValues,
          ['card']
        );

        if (res) {
          dispatch(
            updateIntercom({
              pspdatafilled: 'true',
            })
          );
          dispatch(
            setStageComplete({
              stage: OnboardingStagesMap.SettingPSPConnection.name,
              isCompleted: true,
            })
          );
          dispatch(completeOnboarding(true));
          // navigate(HOME_ROUTE);
          navigateToNextStage();
        }
      } catch (exception) {
        console.log('Error during authentication request');
        submitLogToBugsnag('error', `${exception}`);
      }
    }
  }

  async function skip() {
    navigateToNextStage();
    dispatch(
      setStageComplete({
        stage: OnboardingStagesMap.SettingPSPConnection.name,
        isCompleted: true,
      })
    );
  }

  function goBack() {
    dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name));
    dispatch(
      setStageComplete({
        stage: OnboardingStagesMap.IntegratingPSP1.name,
        isCompleted: false,
      })
    );
  }

  let isDisabled;

  if (pspInputValues) {
    isDisabled = Object.values(pspInputValues)?.some((el: any) => el === '');
  }

  const onInputChange = (e: any, value: string) => {
    setPSPInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  return (
    <motion.div
      {...pageSlideOutForward}
      className='enterCMSConnectionDetails  animate-fade_in_from_right'
    >
      <div className='enterCMSConnectionDetails__container '>
        <div className='enterCMSConnectionDetails__container--top'>
          <h1>
            {selectedPSP === 'other'
              ? t('OnboardingCustomPSP-H1')
              : t('OnboardingPSP-ConnectionH1')}
          </h1>
          <h2 className={`onboarding-psp-${selectedPSP}-connection-h2`}>
            {selectedPSP === 'other'
              ? t('OnboardingCustomPSP-H2')
              : t('OnboardingPSP-ConnectionH2')}
          </h2>
        </div>

        <form
          onSubmit={onContinue}
          id='onboarding-PSPConnection'
          className='enterCMSConnectionDetails__container--center'
        >
          {selectedPSP === 'other' && (
            <>
              {/* ---------------- */}

              <NormalInput
                id={`other-option-${optionType}`}
                value={customOption}
                changeListeners={[(e) => setCustomOption(e.target.value)]}
                focusListeners={[]}
                placeholder={t('CustomPSP-Option')}
                required={true}
                autoFocus={true}
                /* pattern='^[A-Za-z ]{2,30}$' */
                /* title='Name cannot exceed 30 characters' */
              />

              {/* ---------------- */}
            </>
          )}
          {selectedPSP !== 'other' && (
            <>
              {pspInputs.map((el: string, index: number) => {
                return (
                  <div key={index}>
                    <NormalInput
                      changeListeners={[(e) => onInputChange(e, el)]}
                      id={`psp-integration-${el}`}
                      value={pspInputValues ? pspInputValues[el] : ''}
                      placeholder={makeFirstLetterCapital(
                        el.replaceAll('_', ' ')
                      )}
                      required={true}
                      autoFocus={index === 0 ? true : false}
                      classes={`onboarding-psp-${selectedPSP}-${el}-input`}
                      labelClasses={`onboarding-psp-${selectedPSP}-${el}-label`}
                    />
                  </div>
                );
              })}
            </>
          )}
        </form>
        {/* //TODO: Instructed to Disable for now
        {selectedPSP === 'mollie' && (
          <button style={{ width: '160px' }}>
            <a
              target={'_self'}
              href='https://my.mollie.com/oauth2/authorize?approval_prompt=force&scope=payments.read%20payments.write%20customers.read%20customers.write%20profiles.read%20profiles.write%20onboarding.read%20onboarding.write%20orders.write%20orders.read&state=99f7d507b0283a7afb0db1298938c4e8&response_type=code&redirect_uri=https://dashboard.adup.io/onboarding&client_id=app_x2MyAqtAmGdccRwUFehCC7WD'
              rel='mollie.com'
            >
              <img src={hostedMollieButton} alt='Connect via Mollie' />
            </a>
          </button>
        )} */}
        <div className='enterCMSConnectionDetails__container--bottom '>
          <MainPurpleButton
            value={t('Continue')}
            disabled={isDisabled}
            type='submit'
            form={'onboarding-PSPConnection'}
          />
        </div>

        <div className='pt-2'>
          <a className='skip-link margin-left-right-1rem' onClick={goBack}>
            &#8592; {t('GoBack')}
          </a>
          <a
            className='skip-link margin-left-right-1rem'
            onClick={async () => {
              await skip();
            }}
          >
            {t('SkipStep')} &#8594;
          </a>
        </div>

        <div className='enterCMSConnectionDetails__container--footer'>
          <FooterLinks />
        </div>
      </div>
    </motion.div>
  );
};

export default EnterPSPConnectionDetails;
