import { useDispatch, useSelector } from 'react-redux';
import CMSSelector from '../../components/selection/CMSSelector';
import { RootState } from '../../store/store';
import { motion } from 'framer-motion';
import {
  OnboardingStagesMap,
  setStageComplete,
  updateIntercom,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import { useEffect, useState } from 'react';
import './onboarding-page.css';
import '../../styles/components/onboarding/setShopCMS1.scss';
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
// import { submitCustomOption } from '../../lib/api/psp-cms-otherOptions-call';
import { useTranslation } from 'react-i18next';
// import { submitOnboardingStatus } from '../../lib/api/post-onboarding-status';
import get_all_cms_api_call from '../../lib/api/cms/get-all-available-cms';
import { useNavigate } from 'react-router-dom';
import FooterLinks from '../../components/footer/FooterLinks';

const SetShopCMS1 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [allAvailableCMSServices, setAllAvailableCMSServices] = useState([]);

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  const selectedCMS = useSelector(
    (state: RootState) => state.user.company.cmsSelected
  );

  useEffect(() => {
    async function allAvailableCMS() {
      const res: any = await get_all_cms_api_call();
      setAllAvailableCMSServices(res ? res : []);
    }
    allAvailableCMS();
  }, []);

  /* async function handleContinue() {
    if (selectedCMS === 'nocms') {
      const statusRes = await submitOnboardingStatus('cms', 'SocialCommerce');
      dispatch(
        updateIntercom({
          cms: 'social_commerce',
        })
      );
      if (statusRes) {
        skip();
      }
    } else if (
      selectedCMS === 'shopify' ||
      selectedCMS === 'bigcommerce'
    ) {
      const res: any = await submitCustomOption('cms', selectedCMS);
      const statusRes = await submitOnboardingStatus('cms', selectedCMS);
      if (res && statusRes) {
        skip();
      }
    } else {
      if (selectedCMS === 'other') {
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.SettingCMSConnection.name)
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.IntegratingCMS1.name,
            isCompleted: true,
          })
        );
      } else {
        dispatch(
          updateIntercom({
            cms: selectedCMS,
          })
        );
        const statusRes = await submitOnboardingStatus('cms', selectedCMS);
        if (statusRes) {
          dispatch(
            updateOnboardingStage(OnboardingStagesMap.SettingCMSConnection.name)
          );
          dispatch(
            setStageComplete({
              stage: OnboardingStagesMap.IntegratingCMS1.name,
              isCompleted: true,
            })
          );
        }
      }
    }
  } */

  //TODO: When the submitOnboardingStatus ENDPOINT is completed, delete this function and uncomment the above function and use it.
  async function handleContinue() {
    if (selectedCMS === 'nocms') {
      dispatch(
        updateIntercom({
          cms: 'social_commerce',
        })
      );
      skip();
    } else if (
      /* selectedCMS === 'magento' || */
      selectedCMS === 'shopify' ||
      selectedCMS === 'bigcommerce'
    ) {
      // const res: any = await submitCustomOption('cms', selectedCMS);

      skip();
    } else {
      if (selectedCMS === 'other') {
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.SettingCMSConnection.name)
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.IntegratingCMS1.name,
            isCompleted: true,
          })
        );
      } else {
        dispatch(
          updateIntercom({
            cms: selectedCMS,
          })
        );
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.SettingCMSConnection.name)
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.IntegratingCMS1.name,
            isCompleted: true,
          })
        );
      }
    }
  }

  function skip() {
    if (merchantTheme?.is_onboarding_psp_hidden === 'true') {
      dispatch(
        setStageComplete({
          stage: OnboardingStagesMap.IntegratingCMS1.name,
          isCompleted: true,
        })
      );
      dispatch(
        updateOnboardingStage(OnboardingStagesMap.WebshopTransactions.name)
      );
    } else {
      dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name));
      dispatch(
        setStageComplete({
          stage: OnboardingStagesMap.IntegratingCMS1.name,
          isCompleted: true,
        })
      );
    }
  }
  const isDisabled = selectedCMS === '' ? true : false;
  return (
    <motion.div
      {...pageSlideOutForward}
      className='setShopCMS1 animate-fade_in_from_right'
    >
      <div className='setShopCMS1-container'>
        <div className='setShopCMS1--top '>
          <h1>{t('OnboardingCMS-SelectH1')}</h1>
          <h2>{t('OnboardingCMS-SelectH2')}</h2>
        </div>

        {allAvailableCMSServices?.length > 0 ? (
          <CMSSelector options={[...allAvailableCMSServices]} />
        ) : (
          <div className='cmsSelector'>
            <h1>Loading...</h1>
          </div>
        )}

        <motion.div className='setShopCMS1--bottom'>
          <MainPurpleButton
            onClick={() => handleContinue()}
            value={t('Continue')}
            disabled={isDisabled}
            classes='text-white rounded-full'
          />
          <div className='pt-2'>
            <a className='skip-link' onClick={skip}>
              {t('SkipStep')}
            </a>
          </div>
        </motion.div>
        {/* )} */}
      </div>
      <div className='setShopCMS1--footer'>
        <FooterLinks />
      </div>
    </motion.div>
  );
};

export default SetShopCMS1;
