import SimpleCard from '../../../../ui/cards/SimpleCard';
import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/checkoutTracking/trends.scss';
import statsLottie from '../../../../assets/json/lottie_animation/statsInfographicsLottie.json';
import setupTrackingLottie from '../../../../assets/json/lottie_animation/setup_tracking.json';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import CountdownTimer from '../../../../ui/timers/CountdownTimer';
import addDaysToDate from '../../../../lib/helper-functions/addDaysToDate';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import RecommendationItem from './RecommendationItem';
import { DAYS_TO_ADD } from '../../../../lib/constants/abstracts';
import get_recommendations_api_call from '../../../../lib/api/ai-tracker/get_recommendations_api_call';
import SelectableButton from '../../../../ui/buttons/SelectableButton';
import { UnlockPaymentSVG } from '../../../../lib/allCustomSVGs';
import Popup from '../../../../ui/popup/Popup';
import RecommendationsPayment from './RecommendationsPayment';

interface Props {
  apiKey: string;
  isTrackable: boolean;
}

const Trends = ({ isTrackable, apiKey }: Props) => {
  const { t } = useTranslation();
  const [recommendations, setRecommendations] = useState<any[]>([]);
  const ai_tracker_configured_timestamp = useSelector(
    (state: RootState) => state.user.stats.ai_tracker_configured_timestamp
  );

  //Payment popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopupModal = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const [paymentValue, setPaymentValue] = useState<number>(65);

  const futureDate = addDaysToDate(
    ai_tracker_configured_timestamp ? ai_tracker_configured_timestamp : '',
    DAYS_TO_ADD
  );

  const [isTimesUp, setIsTimesUp] = useState<boolean>(false);
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null');

  useEffect(() => {
    if (isTimesUp) {
      getRecommendations();
    }
  }, [isTimesUp]);

  const getRecommendations = async () => {
    setLoading('pending');
    try {
      const res = await get_recommendations_api_call();

      if (res && res?.length > 0) {
        setRecommendations(res); // Use actual response data
        // setRecommendations(dummyRes);
        setLoading('success');
      } else {
        setLoading('null');
      }
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setLoading('error');
    }
  };

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'error' && (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      )}

      <div className='flex-row-full-width-flex-wrap'>
        {isTrackable && !isTimesUp && (
          <SimpleCard>
            <div className='trends'>
              <h3>{t('TrendsText1')}</h3>
              <div className='trends-lottie'>
                <Lottie animationData={statsLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {!isTrackable && !isTimesUp && (
          <SimpleCard>
            <div className='trends'>
              <h3>{t('TrendsText2')}</h3>
              <div className='trends-lottie'>
                <Lottie animationData={setupTrackingLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {isTimesUp && loading === 'success' && (
          <div className='normalForm'>
            <div className='normalForm__top'>
              <div className='shopPage__container1-alignLeft'>
                <SelectableButton
                  icon={UnlockPaymentSVG}
                  title={''}
                  subTitle={t('PayWhatYouWant')}
                  subTitle2={t('UnlockAllRecommendations')}
                  link=''
                  onSelect={() => {
                    setIsPopupOpen(true);
                  }}
                  isSelected={false}
                />
              </div>

              <div className='recommendationItem__container'>
                {recommendations?.map((data: any, index: number) => (
                  <RecommendationItem
                    key={index}
                    data={data}
                    allRecommendations={recommendations}
                    setAllRecommendations={setRecommendations}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        <CountdownTimer
          prefix={t('StillCollectingData')}
          suffix={`${t('remaining')}.`}
          className='dashboardTab__item--left-subtitle'
          futureDate={futureDate}
          setIsTimesUp={setIsTimesUp}
          display={false}
        />
      </div>

      {/* ------share popup start-------- */}
      <Popup
        styles={{
          backgroundColor: 'var(--sidebar-background-color)',
        }}
        setTrigger={togglePopupModal}
        trigger={isPopupOpen}
      >
        <RecommendationsPayment
          setPaymentValue={setPaymentValue}
          paymentValue={paymentValue}
        />
      </Popup>
      {/* ------share popup end-------- */}
    </>
  );
};

export default Trends;
