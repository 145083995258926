import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import {
  OnboardingStagesMap,
  setStageComplete,
  updateIntercom,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import { RootState } from '../../store/store';
import './onboarding-page.css';
import '../../styles/components/onboarding/enterCMSConnectionDetails.scss';
/* import { arrowRightThickSVG, skipArrowSVG } from '../../lib/allCustomSVGs';
import MainSquareButton from '../../ui/buttons/MainSquareButton'; */
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
import NormalInput from '../../ui/inputs/NormalInput';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';
import add_cms_api_call from '../../lib/api/cms/add-cms';
import {
  getCustomInputFields,
  getExtraCustomInputFields,
} from '../../lib/helper-functions/getCustomInputFields';
import ToggleSwitch from '../../ui/buttons/ToggleSwitch';
import isNotEmptyString from '../../lib/helper-functions/isNotEmptyString';
import { Link } from 'react-router-dom';
import FooterLinks from '../../components/footer/FooterLinks';

const EnterCMSConnectionDetails = () => {
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const selectedCMS: any = useSelector(
    (state: RootState) => state.user.company.cmsSelected
  );

  const [cmsInputs, setCMSInputs] = useState<string[]>([]);
  const [cmsInputValues, setCMSInputValues] = useState<any>();
  const [cmsExtraInputs, setCMSExtraInputs] = useState<any[]>([]);
  const [cmsExtraInputValues, setCMSExtraInputValues] = useState<any>();

  const { t } = useTranslation();

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  /* ------other option state ----------*/
  const optionType = 'cms';
  const [customOption, setCustomOption] = useState('');

  const dispatch = useDispatch();

  /* ================================================================================= */
  useEffect(() => {
    const [currentCMS_inputs_array, currentCMS_inputs_object] =
      getCustomInputFields(selectedCMS, onboarding_object?.available_cms);

    setCMSInputs(currentCMS_inputs_array);
    setCMSInputValues(currentCMS_inputs_object);

    ////////////////////////////////////////////////////////////////////////////////
    const [currentCMS_extra_inputs_array, currentCMS_extra_inputs_object] =
      getExtraCustomInputFields(selectedCMS, onboarding_object?.available_cms);

    setCMSExtraInputs(currentCMS_extra_inputs_array);
    setCMSExtraInputValues(currentCMS_extra_inputs_object);
  }, [selectedCMS]);
  /* ================================================================================= */

  /* async function onContinue(e: any) {
    e.preventDefault();
    if (selectedCMS === 'other') {
      const res: any = await submitCustomOption('cms', customOption);
      if (res) {
        const statusRes = await submitOnboardingStatus('cms', customOption);
        if (statusRes) {
          dispatch(
            updateIntercom({
              cms: customOption,
            })
          );
          dispatch(
            updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name)
          );
          dispatch(
            setStageComplete({
              stage: OnboardingStagesMap.SettingCMSConnection.name,
              isCompleted: true,
            })
          );
        }
      }
    } else {
      try {
        const accessToken = store.getState().auth.authorization.accessToken;
        const isWhiteLabel = store.getState().UI?.isWhiteLabel;
        let sellerToken: string;
        if (isWhiteLabel) {
          sellerToken = accessToken?.currentWhiteLabelSellerToken;
        } else {
          sellerToken = accessToken?.sellerToken;
        }

        const requestInfo: RequestInfo = {
          url: CMS_ENDPOINT,
          method: 'POST',
          body: applicationWWWXRLEncodedBodyBuilder({
            cms_type: selectedCMS,
            connection_data: JSON.stringify(cmsInputValues),
            shop_id: shopId,
            is_onboard: 'true', //testing
          }),
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/x-www-form-urlencoded',
            Authorization: accessToken.type + ' ' + sellerToken,
          },
        };

        const actionType = 'ONBOARDING_CMS_CONNECTION';
        const customMsg = 'CMS Connection Successful.';

        const res: any = await asyncHandler(requestInfo, actionType, customMsg);

        const response = res.data;

        if (res.actionType === actionType && response.success === true) {
          dispatch(
            updateIntercom({
              cmsdatafilled: 'true',
            })
          );
          dispatch(
            updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name)
          );
          dispatch(
            setStageComplete({
              stage: OnboardingStagesMap.SettingCMSConnection.name,
              isCompleted: true,
            })
          );
        } else {
        }
      } catch (exception) {
        console.log('Error during authentication request');
      }
    }
  } */

  //TODO: When the submitOnboardingStatus ENDPOINT is completed, delete this function and uncomment the above function and use it.
  async function onContinue(e: any) {
    e.preventDefault();
    if (selectedCMS === 'other') {
      // const res: any = await submitCustomOption('cms', customOption);
      if (true) {
        // const statusRes = await submitOnboardingStatus('cms', customOption);
        if (true) {
          dispatch(
            updateIntercom({
              cms: customOption,
            })
          );
          goToNextPage();
        }
      }
    } else {
      const res: boolean = await add_cms_api_call(
        selectedCMS,
        cmsInputValues,
        cmsExtraInputValues
      );
      if (res) {
        dispatch(
          updateIntercom({
            cmsdatafilled: 'true',
          })
        );

        goToNextPage();
      }
    }
  }

  function goToNextPage() {
    if (merchantTheme?.is_onboarding_psp_hidden === 'true') {
      dispatch(
        setStageComplete({
          stage: OnboardingStagesMap.SettingCMSConnection.name,
          isCompleted: true,
        })
      );
      dispatch(
        updateOnboardingStage(OnboardingStagesMap.WebshopTransactions.name)
      );
    } else {
      dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name));
      dispatch(
        setStageComplete({
          stage: OnboardingStagesMap.SettingCMSConnection.name,
          isCompleted: true,
        })
      );
    }
  }
  function goBack() {
    // dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name));
    dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingCMS1.name));
    dispatch(
      setStageComplete({
        stage: OnboardingStagesMap.IntegratingCMS1.name,
        isCompleted: false,
      })
    );
  }

  let isDisabled;
  if (cmsInputValues) {
    isDisabled = Object.values(cmsInputValues)?.some((el: any) => el === '');
  }

  const onInputChange = (e: any, value: string) => {
    setCMSInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  const onToggleChange = (value: boolean, name: string) => {
    setCMSExtraInputValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <motion.div
      {...pageSlideOutForward}
      className='enterCMSConnectionDetails  animate-fade_in_from_right'
    >
      <div className='enterCMSConnectionDetails__container '>
        <div className='enterCMSConnectionDetails__container--top'>
          <h1>
            {selectedCMS === 'other'
              ? t('OnboardingCustomCMS-H1')
              : t('OnboardingCMS-ConnectionH1')}
          </h1>
          <h2 className={`onboarding-cms-${selectedCMS}-connection-h2`}>
            {selectedCMS === 'other'
              ? t('OnboardingCustomCMS-H2')
              : t('OnboardingCMS-ConnectionH2')}
          </h2>
        </div>

        <form
          onSubmit={onContinue}
          id='onboarding-CMSConnection'
          className='enterCMSConnectionDetails__container--center'
        >
          {selectedCMS === 'other' && (
            <NormalInput
              id={`other-option-${optionType}`}
              value={customOption}
              changeListeners={[(e) => setCustomOption(e.target.value)]}
              focusListeners={[]}
              placeholder={t('CustomCMS-Option')}
              required={true}
              autoFocus={true}
              /* pattern='^[A-Za-z ]{2,30}$' */
              /* title='Name cannot exceed 30 characters' */
            />
          )}
          {selectedCMS !== 'other' && (
            <>
              {cmsInputs.map((el: string, index: number) => {
                return (
                  <div key={index}>
                    <NormalInput
                      changeListeners={[(e) => onInputChange(e, el)]}
                      id={`cms-integration-${el}`}
                      value={cmsInputValues ? cmsInputValues[el] : ''}
                      placeholder={makeFirstLetterCapital(
                        el.replaceAll('_', ' ')
                      )}
                      required={true}
                      autoFocus={index === 0 ? true : false}
                      classes={`onboarding-cms-${selectedCMS}-${el}-input`}
                      labelClasses={`onboarding-cms-${selectedCMS}-${el}-label`}
                    />
                  </div>
                );
              })}
            </>
          )}

          {cmsExtraInputs?.map((el: any, index: number) => {
            return (
              <div className='normalForm__container3--item' key={index}>
                <div className='normalForm__container3--item-content'>
                  {el?.type === 'toggle' ? (
                    <ToggleSwitch
                      message={t('PaymentMethodAlreadyAdded-msg')}
                      disabled={!el?.is_active}
                      key={el?.name}
                      id={el?.name}
                      isOn={
                        cmsExtraInputValues
                          ? cmsExtraInputValues[el?.name]
                          : el.default
                      }
                      title={el?.label}
                      onClick={(value: boolean) =>
                        onToggleChange(value, el.name)
                      }
                      classes='margin-top-2rem'
                    />
                  ) : (
                    <NormalInput
                      changeListeners={[(e) => onInputChange(e, el?.name)]}
                      id={`add-cms-integration-${el?.name}`}
                      value={cmsInputValues ? cmsInputValues[el?.name] : ''}
                      placeholder={el?.label}
                      required={el?.is_required ? true : false}
                      autoFocus={index === 0 ? true : false}
                      classes={`add-cms-${selectedCMS}-${el}-input`}
                      labelClasses={`add-cms-${selectedCMS}-${el}-label`}
                      disabled={el?.is_active ? false : true}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </form>
        <div className='enterCMSConnectionDetails__container--bottom'>
          <MainPurpleButton
            value={t('Continue')}
            disabled={isDisabled}
            type='submit'
            form={'onboarding-CMSConnection'}
          />
        </div>

        <div>
          <span className='skip-link margin-left-right-1rem' onClick={goBack}>
            &#8592; {t('GoBack')}
          </span>
          <span
            className='skip-link margin-left-right-1rem'
            onClick={goToNextPage}
          >
            {t('SkipStep')} &#8594;
          </span>
        </div>

        <div className='enterCMSConnectionDetails__container--footer'>
          <FooterLinks />
        </div>
      </div>
    </motion.div>
  );
};

export default EnterCMSConnectionDetails;
