import '../../styles/inputs/normalSelectInput.scss';

interface SelectOptionArr {
  OptionValue: string;
  OptionName: string;
  OptionDisabled?: boolean;
}

interface Props {
  defaultValue: string;
  setSelect: (input: string) => void;
  selectOptionArr: SelectOptionArr[];
  placeholder?: string;
  id: string;
  disabled?: boolean;
}

const NormalSelectInput: React.FC<Props> = ({
  defaultValue,
  setSelect,
  selectOptionArr,
  placeholder,
  id,
  disabled,
}) => {
  function handleSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelect(event.target.value);
  }

  return (
    <div className='normalSelectInput-outer'>
      <div className='normalSelectInput'>
        <label htmlFor={id}>{placeholder}</label>
        <select
          className='w-full peer border border-solid border-[#dbdbdb] rounded-lg  p-2 pb-1 outline-none focus:outline-none focus:border-[#cecece] transition-all duration-500 hover:border-[#cecece]'
          onChange={(e) => handleSelect(e)}
          defaultValue={defaultValue}
          value={defaultValue}
          id={id}
          disabled={disabled}
        >
          {selectOptionArr.map((selectOption, i) => {
            return (
              <option
                key={i}
                value={selectOption.OptionValue}
                disabled={selectOption.OptionDisabled}
              >
                {selectOption.OptionName}
              </option>
            );
          })}
        </select>
      </div>
      <div className='normalSelectInput-validation-text'></div>
    </div>
  );
};

export default NormalSelectInput;
