import { AnimatePresence, motion } from 'framer-motion';
import { crossSVG } from '../../lib/allCustomSVGs';
import '../../styles/popup/popup.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';

interface Props {
  setTrigger: () => void;
  trigger: boolean;
  styles?: any;
  children: React.ReactNode;
  isCloseBtnHidden?: boolean;
}

const Popup = ({
  trigger,
  setTrigger,
  styles,
  children,
  isCloseBtnHidden = false,
}: Props) => {
  if (!trigger) return null;
  return (
    <div className='popup'>
      <div
        className='popup__overlay'
        onClick={() => {
          setTrigger();
        }}
      ></div>

      <AnimatePresence>
        <motion.div
          initial={{ x: '-50%', y: '-50%', opacity: 0, scale: 0.1 }}
          exit={{ opacity: 0.5, scale: 0.5 }}
          animate={{ x: '-50%', y: '-50%', opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          style={styles}
          className='popup__modal '
        >
          {!isCloseBtnHidden && (
            <div
              onClick={() => {
                setTrigger();
              }}
              className='popup__modal--closeBtn'
            >
              <CustomSVGs svg={crossSVG}></CustomSVGs>
            </div>
          )}
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Popup;
