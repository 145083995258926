import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import '../../styles/inputs/phoneInput.scss';

type Props = {
  id: string;
  value: string;
  countryCode: string;
  placeholder: string;
  classes?: string;
  changeListeners: ((event: React.ChangeEvent<HTMLInputElement>) => void)[];
  updateCountry: (countryCode: string) => void;
  pattern?: string;
  title?: string;
  required?: boolean;
};

const PhoneInput: React.FC<Props> = ({
  id,
  value,
  countryCode,
  placeholder,
  classes,
  changeListeners,
  updateCountry,
  required,
  pattern,
  title,
}) => {
  const countries = useSelector(
    (state: RootState) => state.app.countries.available
  );

  const [selectedCountry, setSelectedCountry] = useState(countryCode);
  const [focused, setFocused] = useState<boolean>(false);
  const [onlyOnFocus, setOnlyOnFocus] = useState<boolean>(false);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setOnlyOnFocus(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    setOnlyOnFocus(false);
  };

  useEffect(() => {
    updateCountry(selectedCountry);
  }, [selectedCountry]);

  return (
    <div className='phoneInput-outer'>
      <div className='phoneInput'>
        <select
          onChange={(e) => setSelectedCountry}
          defaultValue={selectedCountry}
        >
          {Object.keys(countries).map((countryKey: any, key: number) => {
            return (
              <option key={key} value={countries[countryKey].countryCode}>
                {countries[countryKey].flag} &nbsp;
                {countries[countryKey].dialCode}
              </option>
            );
          })}
        </select>
        <input
          id={id}
          type='tel'
          value={value}
          placeholder={placeholder}
          required={required}
          onFocus={handleFocus}
          onBlur={handleBlur}
          data-focused={focused.toString()}
          pattern={pattern}
          title={title}
          className='main-phone-input'
          style={{ fontFamily: 'var(--main-font-secondary)' }}
          onChange={(event) => {
            for (const listener of changeListeners) {
              listener(event);
            }
          }}
        />
      </div>
      <div className='phoneInput-validation-text'>{onlyOnFocus && title}</div>
    </div>
  );
};

/* type BasicPhoneInputSelectOptionProps = {
  name: string;
  onclick: () => void;
  key: number;
};

const BasicPhoneInputSelectOption: React.FC<
  BasicPhoneInputSelectOptionProps
> = ({ name, onclick }) => {
  return <div onClick={() => onclick()}>{name}</div>;
};

type CountrySelectorProps = {
  options: Country[];
  setCountry: Dispatch<SetStateAction<string>>;
  country: Country;
  updateCountry: (countryCode: string) => void;
}; */

/* const BasicPhoneInputCountrySelect: React.FC<CountrySelectorProps> = ({
  options,
  country,
  setCountry,
  updateCountry,
}) => {
  const [expanded, setExpanded] = useState(false);

  if (expanded)
    return (
      <div onBlur={() => setExpanded(false)}>
        {options.map((option: Country, key: number) => {
          return (
            <BasicPhoneInputSelectOption
              key={key}
              name={`${option.flag} ${option.dialCode}  ${option.name}`}
              onclick={() => {
                setExpanded(false);
                setCountry((country) => option.countryCode);
              }}
            />
          );
        })}
      </div>
    );

  return (
    <button onFocus={() => setExpanded(true)} className='border p-3 w-1/2'>
      {`${country.flag}  ${country.dialCode}`}
    </button>
  );
}; */

export default PhoneInput;
