import React from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// Updated dataset with realistic web usage data over a week
const userActivityData = [
  { day: 'Sun', hour: 0, users: 120 },
  { day: 'Sun', hour: 6, users: 80 },
  { day: 'Sun', hour: 12, users: 500 },
  { day: 'Sun', hour: 18, users: 650 },

  { day: 'Mon', hour: 0, users: 50 },
  { day: 'Mon', hour: 6, users: 300 },
  { day: 'Mon', hour: 12, users: 700 },
  { day: 'Mon', hour: 18, users: 850 },

  { day: 'Tue', hour: 0, users: 40 },
  { day: 'Tue', hour: 6, users: 280 },
  { day: 'Tue', hour: 12, users: 680 },
  { day: 'Tue', hour: 18, users: 820 },

  { day: 'Wed', hour: 0, users: 30 },
  { day: 'Wed', hour: 6, users: 290 },
  { day: 'Wed', hour: 12, users: 690 },
  { day: 'Wed', hour: 18, users: 830 },

  { day: 'Thu', hour: 0, users: 50 },
  { day: 'Thu', hour: 6, users: 270 },
  { day: 'Thu', hour: 12, users: 710 },
  { day: 'Thu', hour: 18, users: 860 },

  { day: 'Fri', hour: 0, users: 70 },
  { day: 'Fri', hour: 6, users: 330 },
  { day: 'Fri', hour: 12, users: 750 },
  { day: 'Fri', hour: 18, users: 900 },

  { day: 'Sat', hour: 0, users: 100 },
  { day: 'Sat', hour: 6, users: 350 },
  { day: 'Sat', hour: 12, users: 800 },
  { day: 'Sat', hour: 18, users: 950 },
];

interface Props {
  width: string | number;
  height: number;
  title?: string;
}

const UserActivityBubbleChart = ({ width, height, title }: Props) => {
  return (
    <div className='pieChartComponent'>
      {title && <h2 className='pieChartComponent__title'>{title}</h2>}
      <div className='pieChartComponent__chart'>
        <ResponsiveContainer width={width} height={height}>
          <ScatterChart margin={{ top: 20, right: 30, bottom: 20, left: 20 }}>
            <CartesianGrid />
            <XAxis
              dataKey='hour'
              type='number'
              name='Hour'
              domain={[0, 24]}
              tickFormatter={(tick) => `${tick}:00`}
              label={{
                value: 'Time of Day',
                position: 'insideBottomRight',
                offset: -10,
              }}
            />
            <YAxis
              dataKey='day'
              type='category'
              name='Day'
              label={{
                value: 'Day of Week',
                angle: -90,
                position: 'insideLeft',
              }}
            />
            <ZAxis
              dataKey='users'
              range={[50, 1000]}
              name='User Count'
              // unit=' users'
            />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              formatter={(value, name) => [`${value}`, name]}
            />
            <Scatter data={userActivityData} fill='#8884d8' />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default UserActivityBubbleChart;
