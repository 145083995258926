import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DownArrow from '../../assets/svg/DownArrow';
import {
  OnboardingStagesMap,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import { RootState, useAppDispatch } from '../../store/store';

import '../../styles/components/onboarding/onboardingNavbarDropdown.scss';
import { useTranslation } from 'react-i18next';
import getOnboardingStepLogic from '../../lib/helper-functions/getOnboardingStepLogic';

const OnboardingNavbarDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const onboardingState = useSelector(
    (state: RootState) => state.user.onboarding
  );

  const merchantConfig = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  const [stageName, setStageName] = useState('');
  const [stepNumber, setStepNumber] = useState(1);

  const [dropdownShowing, setDropdownShowing] = useState(false);

  useEffect(() => {
    const currentStage = onboardingState.currentStage;

    setStageName(OnboardingStagesMap[currentStage]?.displayTitle);
    setStepNumber(OnboardingStagesMap[currentStage]?.stepNumber);
  }, [onboardingState.currentStage]);

  if (
    onboardingState.currentStage === OnboardingStagesMap.SigningIn.name ||
    onboardingState.currentStage === OnboardingStagesMap.SigningUp.name ||
    onboardingState.currentStage === ''
  ) {
    return <></>;
  }

  const renderOption = (
    newStep: number,
    oldStep: number,
    count: number,
    isCompleted: boolean,
    stage: any
  ) => {
    return (
      <b
        data-is-dashboard-dropdown-item-selected={
          oldStep === stepNumber ? 'true' : 'false'
        }
        key={count}
        className={
          'cursor-pointer font-light text-xs dropdown-item onboardingNavbarDropdown__dropdown--item' +
          (!isCompleted ? ' text-slate-300' : '')
        }
        onClick={() => {
          if (isCompleted) {
            dispatch(updateOnboardingStage(stage.name));
            setDropdownShowing(false);
          }
        }}
      >
        <span>{`Step ${newStep}:`}</span>
        <span>{` ${t(stage.displayTitle)}`}</span>
      </b>
    );
  };

  function createStagesDropdownOptions() {
    return Object.keys(onboardingState.stagesCompleted).map((key, count) => {
      const stage = OnboardingStagesMap[key];
      const isCompleted = onboardingState.stagesCompleted[key];
      const step = stage.stepNumber;

      if (
        getOnboardingStepLogic(
          step,
          merchantConfig.is_onboarding_psp_hidden,
          merchantConfig.is_onboarding_cms_hidden
        )
      ) {
        const newStep = getOnboardingStepLogic(
          step,
          merchantConfig.is_onboarding_psp_hidden,
          merchantConfig.is_onboarding_cms_hidden
        );
        return renderOption(newStep, step, count, isCompleted, stage);
      } else {
        return null;
      }
    });
  }

  return (
    <div className='onboardingNavbarDropdown'>
      <div className='onboarding-stage-selector'>
        {/* <label htmlFor='stage-change'>{ 'Step' + stepNumber +  ': ' + stageName}</label> */}
        <div className={'dropdown ' + (dropdownShowing ? ' is-active' : '')}>
          <div className='onboardingNavbarDropdown__dropdown dropdown-trigger'>
            <div className='onboardingNavbarDropdown__dropdown--text'>
              <b>
                Step{' '}
                {getOnboardingStepLogic(
                  stepNumber,
                  merchantConfig.is_onboarding_psp_hidden,
                  merchantConfig.is_onboarding_cms_hidden
                )}
                :
              </b>
              <b>{t(stageName)}</b>
            </div>
            <span onClick={() => setDropdownShowing(!dropdownShowing)}>
              <DownArrow />
            </span>
          </div>
          <div className='dropdown-menu z-99' id='dropdown-menu' role='menu'>
            <div className='dropdown-content onboardingNavbarDropdown__dropdown--container'>
              {createStagesDropdownOptions()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingNavbarDropdown;
