export default function getOnboardingStepLogic(
  step: number,
  isOnboardingPSPHidden: string,
  isOnboardingCMSHidden: string
): number {
  /* -----------always skip first two steps (login and register)---------- */
  if (step < 3) {
    return 0;
  }

  /* ---------if cms hidden skip it---------- */
  if (isOnboardingCMSHidden === 'true') {
    if (step === 5 || step === 6) {
      return 0;
    }
  }

  /* ---------if psp is hidden skip it------------ */
  if (isOnboardingPSPHidden === 'true') {
    if (step === 7 || step === 8) {
      return 0;
    }
  }

  /* --------if both cms & psp are visible step 9 goes to 3--------- */
  if (
    isOnboardingPSPHidden === 'true' &&
    isOnboardingCMSHidden === 'true' &&
    step === 9
  ) {
    return 3;
  }

  /* --------if only cms is visible step 5 goes to 3--------- */
  if (
    isOnboardingPSPHidden === 'true' &&
    isOnboardingCMSHidden !== 'true' &&
    step === 5
  ) {
    return 3;
  }
  /* --------if only cms is visible step 6 goes to 4--------- */
  if (
    isOnboardingPSPHidden === 'true' &&
    isOnboardingCMSHidden !== 'true' &&
    step === 6
  ) {
    return 4;
  }
  /* --------if only cms is visible step 9 goes to 5--------- */
  if (
    isOnboardingPSPHidden === 'true' &&
    isOnboardingCMSHidden !== 'true' &&
    step === 9
  ) {
    return 5;
  }

  /* --------if only psp is visible step 7 goes to 3--------- */
  if (
    isOnboardingPSPHidden !== 'true' &&
    isOnboardingCMSHidden === 'true' &&
    step === 7
  ) {
    return 3;
  }
  /* --------if only psp is visible step 8 goes to 4--------- */
  if (
    isOnboardingPSPHidden !== 'true' &&
    isOnboardingCMSHidden === 'true' &&
    step === 8
  ) {
    return 4;
  }
  /* --------if only psp is visible step 9 goes to 5--------- */
  if (
    isOnboardingPSPHidden !== 'true' &&
    isOnboardingCMSHidden === 'true' &&
    step === 9
  ) {
    return 5;
  }

  /* ---------Decrease 2 by remaining steps----------- */
  return step - 2;
}
